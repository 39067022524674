.map-container {
    height: 500px;
    width: 100%;
    margin-top: 5rem;
  }
  
  .popup-content {
    text-align: center;
  }
  
  .popup-content h4 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
  }
  
  

  .popup-contentname{
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 2rem;

  }

  .popup-contentButton{
    margin-top: 1rem;
  }


  .project-details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2rem 0;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 5rem;
  }
  
  .address-section {
    flex-basis: 70%; /* Left section 70% width */
    text-align: left;
    padding: 1rem;
  }
  
  .address-sectionRight {
    flex-basis: 30%; /* Right section 30% width */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 1rem;
  }
  
  .rera-qr-container {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Ensure QR code is on the far right */
    width: 100%;
  }
  
  .rera-section {
    flex-grow: 1; /* Allow the RERA section to take up available space */
    text-align: left; /* Align RERA section text to the left */
    margin-right: 1rem; /* Add space between the RERA text and the QR code */
  }
  
  .qr-section {
    flex-shrink: 0; /* Prevent the QR section from shrinking */
    text-align: right; /* Ensure the QR code aligns to the right */
    padding: 16px;
  }
  
  .qr-code-image {
    width: 150px; /* Adjust width of QR code */
    height: 150px;
    object-fit: contain;
  }
  
  /* Address and RERA text */
  .address-sectiontitleaddress {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.3rem;
    margin-bottom: 0.5rem;
  }
  
  .address-sectionaddress {
    font-size: 0.7rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
  
  /* RERA Section text */
  .rera-section p {
    font-size: 0.7rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    margin-bottom: 0.5rem;
  }


  .custom-marker img {
    width: 100px;
    height: 70px;
    object-fit: contain;
  }
  
  
  /* Responsive adjustments */
  @media screen and (max-width: 786px) {
    .project-details {
      flex-direction: column;
      align-items: center;
    }
  
    .address-section,
    .address-sectionRight {
      width: 100%; /* Full width for both sections on small screens */
      text-align: left;
      padding: 1rem;
      line-height: 1.5;
    }
  
    .qr-code-image {
      width: 120px;
      height: 120px; /* Adjust size for smaller screens */
    }
  }
  

  /* Media query for screens 786px or smaller */
@media screen and (max-width: 786px) {
    .map-container {
      height: 300px; /* Decrease height on smaller screens */
      width: 100%; /* Set width to 100% for smaller screens */
      margin-bottom: 8rem; /* Adjust bottom margin */
    }
  
    .popup-content h4 {
      font-size: 1rem; /* Adjust font size */
    }
  
    .directions-button {
      padding: 4px 8px; /* Smaller padding for buttons */
      font-size: 0.8rem; /* Adjust font size for button */
    }
  
    .popup-contentname {
      margin-bottom: 1rem; /* Adjust margin for the project name */
      font-size: 0.9rem; /* Reduce font size */
    }
  }