/* Base styles for the button */
.btn2 {
  background-color: transparent;
  color: #fff;
  padding: 17px 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  border: 2px solid #fff;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease, transform 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* Optional: add margin to separate buttons */
}

/* Hover styles for the button */
.btn2:hover {
  background-color: #000;
  border: 2px solid #000;
  transform: scale(1.1);
}

/* Base styles for the icon */
.icon2-HomeIntro {
  margin-left: 10px;
  font-size: 1.2em;
  transition: transform 0.3s ease, margin-left 0.3s ease;
}

/* Hover styles for the icon */
.btn2:hover .icon2-HomeIntro {
  transform: translateX(5px);
  margin-left: 15px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .btn2 {
    padding: 14px 25px; /* Adjust padding for smaller screens */
    font-size: 15px; /* Adjust font size for smaller screens */
  }

  .icon2-HomeIntro {
    font-size: 1em; /* Adjust icon size for smaller screens */
    margin-left: 8px; /* Adjust margin for smaller screens */
  }

  .btn2:hover .icon2-HomeIntro {
    margin-left: 12px; /* Adjust hover spacing for smaller screens */
  }
}

@media (max-width: 480px) {
  .btn2 {
    font-size: 14px; /* Further adjust font size for very small screens */
    padding: 12px 20px; /* Further adjust padding for very small screens */
    margin: 0 auto; /* Center button for very small screens */
  }

  .icon2-HomeIntro {
    font-size: 0.9em; /* Further adjust icon size for very small screens */
    margin-left: 6px; /* Further adjust margin for very small screens */
  }

  .btn2:hover .icon2-HomeIntro {
    margin-left: 10px; /* Adjust hover spacing for very small screens */
  }
}
