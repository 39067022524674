/* Container */
.location-highlight-container {
  text-align: left; /* Align everything to the left */
  padding: 2rem;
  margin-bottom: 4rem;
  width: 80%;
  margin: 0 auto;
}

/* Title */
.location-highlight-title {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 2rem;
  letter-spacing: 0.2rem;
  margin-top: 4rem;
}

/* Sentence as points */
.location-highlight-points {
  list-style-type: disc; /* Use bullet points */
  padding-left: 4rem; /* Add left padding for list alignment */
  font-size: 1rem;
  font-weight: 300;
  color: #272727;
  line-height: 1.6;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.location-highlight-points li {
  margin-bottom: 1.2rem; /* Add spacing between sentences */
}


/* No locations message */
.no-locations-message {
  font-size: 1.1em;
  color: #888;
  margin: 20px 0;
}

/* Location grid */
.location-highlight-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Location card */
.location-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  background-color: #fff;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.location-card:hover {
  transform: translateY(-5px);
}

.location-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
}

/* Location info */
.location-info {
  text-align: center;
  min-height: 70px;
}

.location-name {
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.location-distance {
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #555;
  position: relative;
  bottom: 0.1rem;
}

/* Responsive styles for screens 786px or smaller */
@media (max-width: 786px) {
  .location-highlight-container {
    padding: 1.5rem;
  }

  .location-highlight-title {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .location-highlight-points {
    font-size: 0.8rem;
    line-height: 1.4;
    max-width: 90%; /* Adjust width for better readability */
  }

  .location-card {
    width: 100%; /* Full width on smaller screens */
    margin: 1rem 0;
  }

  .location-icon {
    width: 40px;
    height: 40px;
  }

  .location-name {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
  }

  .location-distance {
    font-size: 1.1rem;
  }
}
