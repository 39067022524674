.CustomLink {
    margin-top: 20px;
    display: inline-flex; /* Flex to align icon and text */
    align-items: center;
    font-size: 1.2rem;
    color: white;
    text-decoration: none; /* Remove underline */
    transition: color 0.3s ease, gap 0.3s ease; /* Remove border-bottom transition */
    gap: 5px; /* Default gap between text and icon */
  }
  
  .CustomLinkIcon {
    transition: transform 0.3s ease; /* Smooth transition for the icon */
  }
  
  .CustomLink:hover {
    color: #ccc;
    gap: 10px; /* Increase gap on hover */
    text-decoration: none; /* Ensure underline is removed on hover */
  }
  
  .CustomLink:hover .CustomLinkIcon {
    transform: translateX(5px); /* Move icon slightly to the right on hover */
  }
  
  /* Ensure no underline even if there are browser default styles */
  .CustomLink:focus, 
  .CustomLink:visited, 
  .CustomLink:active {
    text-decoration: none; /* Remove underline for all states */
  }
  

  @media (max-width: 786px) {
    .CustomLink {
      font-size: 1rem; /* Reduce font size */
      margin-top: 10px; /* Adjust margin for smaller screens */
      gap: 3px; /* Reduce gap between text and icon */
    }
  
    .CustomLink:hover {
      gap: 7px; /* Slightly reduce gap on hover for smaller screens */
    }
  
    .CustomLink:hover .CustomLinkIcon {
      transform: translateX(3px); /* Adjust icon movement on hover */
    }
  }