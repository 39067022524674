.privacy-policy-container {
    padding: 40px;
    max-width: 800px;
    margin: 50px auto;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
}

.privacy-policy-container h1 {
    font-size: 28px;
    font-weight: bold;
    color: #222;
    text-align: center;
    margin-bottom: 20px;
}

.privacy-policy-container h2 {
    font-size: 22px;
    margin-top: 25px;
    color: #2a2a2a;
    border-bottom: 2px solid #007BFF;
    padding-bottom: 5px;
}

.privacy-policy-container h3 {
    font-size: 18px;
    margin-top: 15px;
    color: #444;
}

.privacy-policy-container p {
    font-size: 16px;
    color: #555;
    margin-bottom: 15px;
}

.privacy-policy-container ul {
    padding-left: 20px;
}

.privacy-policy-container ul li {
    margin-bottom: 8px;
    font-size: 16px;
    color: #666;
    list-style-type: disc;
}

.privacy-policy-container a {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
}

.privacy-policy-container a:hover {
    text-decoration: underline;
}

.privacy-policy-container .contact-info {
    margin-top: 20px;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 5px;
}

.privacy-policy-container .contact-info ul {
    list-style: none;
    padding-left: 0;
}

.privacy-policy-container .contact-info ul li {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    margin-bottom: 5px;
}

.privacy-policy-container .cookie-notice {
    padding: 15px;
    background: #FFF3CD;
    color: #856404;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 14px;
}

/* 🔹 Mobile Responsiveness */
@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 20px;
        margin: 30px auto;
        width: 90%;
    }

    .privacy-policy-container h1 {
        font-size: 24px;
    }

    .privacy-policy-container h2 {
        font-size: 20px;
    }

    .privacy-policy-container h3 {
        font-size: 17px;
    }

    .privacy-policy-container p,
    .privacy-policy-container ul li {
        font-size: 15px;
    }

    .privacy-policy-container .cookie-notice {
        font-size: 13px;
        padding: 12px;
    }

    .privacy-policy-container .contact-info {
        padding: 12px;
    }
}