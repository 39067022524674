/* Default styles */
/* TestimonialCard.css */

.testimonial-card {
    width: 1000px;
    height: auto;
    margin: auto; /* Center the card horizontally */
    padding: 20px;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Arrange children vertically */
    justify-content: left; /* Center children vertically */
    align-items: left; /* Center children horizontally */
    scroll-snap-align: start;
    margin-bottom: 1rem;
}

.quote-section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center; /* Center the quote horizontally */
    text-align: left; /* Center text horizontally */
    padding: 50px;
    /* border: 1px solid #DF161C; */
}

.quote-section p {
    font-size: 18px;
    color: #666;
    margin-top: 10px;
    line-height: 1.5;
    font-weight: 300;
}

.QuoteLeft {
    font-size: 50px;
    color: #F2F5F5;
    margin-right: 20px;
}

.QuoteRight {
    font-size: 50px;
    color: #F2F5F5;
    margin-left: 20px;
}

.testimonial-section {
    margin-top: 10px;
    padding: 20px;
    border-radius: 8px;
    font-weight: 400;
    text-align: left; /* Center text horizontally */
    text-transform: uppercase;
}

.testimonial-image {
    /* Add your image styles here */
    width: 50px; /* Example width */
    height: 50px; /* Example height */
    border-radius: 50%; /* Example border radius for a circular image */
    /* Center the image */
    display: block;
    margin: 1rem auto 0; /* Add margin-top: 1rem and center horizontally */
}

/* Responsive styles */
@media (max-width: 1700px) {
    .testimonial-card {
        width: 90%; /* Adjust card width for smaller screens */
        margin-bottom: 1rem; /* Adjust margin-bottom for spacing */
    }

    .testimonial-title {
        font-size: 40px; /* Adjust title font size for smaller screens */
    }

    .quote-section p{
        font-size: 13px;
    }

    .QuoteLeft {
        font-size: 20px;

    }
    
    .QuoteRight {
        font-size: 20px;

    }

    .testimonial-section{
        font-size: 10px;


    }
}

/* Styles for screens with a maximum width of 786px */
@media (max-width: 786px) {
    .testimonial-card {
        width: 100%; /* Full width for smaller screens */
        padding: 10px; /* Adjust padding */
    }

    .quote-section {
        flex-direction: column; /* Stack quote section content vertically */
        padding: 20px; /* Adjust padding */
        border-width: 1px; /* Adjust border width */
    }

    .quote-section p {
        font-size: 16px; /* Adjust font size for smaller screens */
    }

    .QuoteLeft, .QuoteRight {
        font-size: 30px; /* Adjust font size for quotes */
        margin: 10px; /* Adjust margin for quotes */
    }

    .testimonial-section {
        padding: 10px; /* Adjust padding */
    }

    .testimonial-image {
        width: 40px; /* Adjust width for smaller screens */
        height: 40px; /* Adjust height for smaller screens */
    }
}
