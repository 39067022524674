/* Container for the entire carousel component */
.blog-latest-container {
    width: 100%;
    padding: 100px;
    background-color: #1A1B1F; /* Adjust the background color if needed */
    box-sizing: border-box;
    margin: 0 auto;
  }
  
  /* Container styling for the header and link */
  .blog-latest-header-container {
    display: flex;
    align-items: center; /* Ensures vertical alignment is centered */
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1rem; /* Add space below the header */
  
  }
  
  /* Header styling */
  .blog-latest-Header {
    font-size: 2rem;
    text-transform: uppercase;
    color: #fff;
    margin: 0; /* Removes default margin to better control spacing */
    flex-grow: 1; /* Allows the header to take up available space */
  }
  
  /* Link styling */
  .carousel-link {
    display: flex;
    align-items: center;
    color: black; /* Bootstrap primary blue, you can choose your color */
    text-decoration: none;
    font-weight: bold;
  }
  
  .iconLinkCarousel {
    margin-left: 10px;
  }
  
  .blog-latest-card-link {
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Inherits text color from parent */
  }
  
  
  /* Individual card styling */
  .blog-latest-card {
    padding: 16px;
    margin: 10px;
    background-color: #1A1B1F;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .blog-latest-card:hover {
    transform: translateY(-5px);
    
  }
  
  /* Image styling */
  .blog-latest-photo {
    width: 100%;
    height: 400px; /* Fixed height, or use object-fit: cover; */
    object-fit: cover;
    border-radius: 20px;
  }
  
  /* Title styling */
  .blog-latest-title {
    font-size: 18px;
    color: #fff;
    margin-top: 12px;
  }
  
  /* Details paragraph styling */
  .blog-latest-details {
    color: #fff;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 12px;
  }
  
  /* Button styling */
  .blog-latest-button {
    background-color: #007bff; /* Bootstrap primary blue, you can choose your color */
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .blog-latest-button:hover {
    background-color: #0056b3; /* Darken button color on hover */
  }


/* Scoped dots styling for the slider within blog-latest-container */
.blog-latest-container .slick-dots li button:before {
    font-size: 12px;
    color: white; /* Change dot color to white */
    opacity: 0.5; /* Adjust opacity to make it less bright by default */
    margin-top: 3rem;
  }
  
  .blog-latest-container .slick-dots li.slick-active button:before {
    opacity: 1; /* Make the active dot fully visible */
    color: white; /* Keep active dot white */
  }
  
  
  
  
  @media (max-width: 786px) {
    /* Adjust the overall container */
    .blog-latest-container {
      width: 100%; /* Full width for better space utilization */
      padding: 20px; /* Reduced padding */
      padding-top: 3rem;
      padding-bottom: 5rem;
    }
  
    /* Adjust header and link container */
    .blog-latest-header-container {
      flex-direction: column; /* Stack elements vertically */
      align-items: flex-start; /* Align items to the start */
    }
  
    /* Header styling adjustments */
    .blog-latest-Header {
      font-size: 1.5rem; /* Smaller font size */
      margin-bottom: 10px; /* Add space between header and link */
    }
  
    /* Link styling adjustments */
    .carousel-link {
      margin-bottom: 10px; /* Add space below the link */
    }
  
    /* Card adjustments */
    .blog-latest-card {
      padding: 10px; /* Reduced padding inside cards */
      margin: 5px; /* Reduced margin between cards */
    }
  
    /* Image adjustments */
    .blog-latest-photo {
      height: 200px; /* Reduced height for images */
    }
  
    /* Title and details adjustments */
    .blog-latest-title {
      font-size: 16px; /* Smaller title font size */
    }
  
    .blog-latest-details {
      font-size: 12px; /* Smaller detail font size */
    }
  
    /* Button styling adjustments */
    .blog-latest-button {
      padding: 6px 12px; /* Smaller button padding */
    }
  }
  