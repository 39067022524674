/* Main container styling */
.key-members-containerMain {
    width: 100%;
    padding: 3rem 0; /* Add padding for top and bottom */
    text-align: left;
  }
  
  /* Title styling */
  .key-members-title {
    font-size: 1.7rem;
    font-weight: 300;
    color: #000;
    margin-bottom: 2rem;
    text-transform:capitalize;
    margin-left: 16rem;
  }
  
  /* Carousel container */
  .key-members-container {
    width: 50%; /* Adjust width to give some space around the carousel */
    margin: 0 auto; /* Center the carousel */
    background-color: #ffffff;
    padding: 20px;
  }
  
  /* Individual key member card */
  .key-member-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  /* Photo styling */
  .key-member-photo {
    height: 700px;
    object-fit: cover; /* Make sure the image fits well inside the circle */
    margin-bottom: 20px;
  }
  
  /* Key member name */
  .key-member-name {
    font-size: 1.7rem;
    font-weight: bold;

    color: #000;
    text-transform: capitalize;
  }
  
  /* Key member position */
  .key-member-position {
    font-size: 0.8rem;
    color: #555;
    margin-bottom: 2rem;
  }
  
/* Key member sentence */
.key-member-sentence {
    font-size: 1rem;
    line-height: 1.6;
    color: #777;
    text-align: left;
    max-width: 80%; /* Limit the width of text */
    margin: 0 auto;
    margin-bottom: 4rem;
    white-space: pre-line; /* Preserves line breaks */
    line-height: 1.9;
  }


  .carousel .control-dots .dot {
    background-color: #666; /* Make dots black */
    width: 12px;
    height: 12px;
    margin: 0 8px;
    border-radius: 50%;
    opacity: 1; /* Ensure dots are fully visible */
  }
  
  /* Add hover effect on dots */
  .carousel .control-dots .dot:hover {
    background-color: #000; /* Change dot color on hover */
  }
  
  .carousel .control-dots .dot.selected {
    background-color: #000; /* Make selected dot red */
  }
  
/* Media queries for screens 786px and smaller */
@media (max-width: 786px) {
    .key-members-title {
      font-size: 1.4rem;
      margin-left: 1rem; /* Reduce the left margin for mobile */
      text-align: center; /* Center the title for mobile screens */
    }
  
    .key-members-container {
      width: 90%; /* Make the container wider for smaller screens */
      padding: 10px; /* Reduce padding for smaller screens */
    }
  
    .key-member-photo {
      height: 250px; /* Reduce the photo height for smaller screens */
      width: 100%; /* Make the image full-width */
      object-fit: cover;
      margin-bottom: 15px;
    }
  
    .key-member-name {
      font-size: 1.2rem; /* Reduce font size for smaller screens */
      margin-bottom: 1.5rem;
    }
  
    .key-member-position {
      font-size: 0.7rem; /* Slightly reduce font size */
    }
  
    .key-member-sentence {
      font-size: 0.9rem; /* Reduce font size */
      line-height: 1.5;
      max-width: 100%; /* Allow the text to fill the container */
      margin-bottom: 2rem;
    }
  
    .carousel .control-dots .dot {
      width: 10px; /* Slightly smaller dots */
      height: 10px;
    }
  }