.AllIntroTemplate {
  position: relative;
  background-size: cover;
  background-position: center; /* Updated for smooth parallax scrolling */
  background-attachment: fixed; /* Makes background sticky */
  background-repeat: no-repeat;
  height: 70vh; /* Adjust the height as necessary */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -6rem;
  z-index: 1; /* Ensure it's on top of other elements */
  transition: background-position 0.2s ease-in-out;
}

.AllIntroTemplate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Darken the background with a black overlay */
  z-index: 1;
}

.ContentContainer {
  position: relative;
  z-index: 2; /* Ensure the content is above the dark overlay */
  padding: 100px;
  border-radius: 10px;
  text-align: left;
  color: white;
  margin-left: 4rem;
}

.TitleContainer h1 {
  font-size: 1rem; /* Adjust to make the title smaller than the sentence */
  font-weight: bold;
  margin-bottom: -3rem;
  text-transform: uppercase;
}

.SentenceContainer p {
  font-size: 3rem; /* Adjust the font size of the sentence */
  font-weight: 700;
  line-height: 1.6;
  margin-right: 40rem;
}

/* Media query for screens 1300px and below */
@media screen and (max-width: 1300px) {
  .ContentContainer {
    padding: 80px;
    margin-left: 3rem;
  }
  
  .TitleContainer h1 {
    font-size: 0.9rem;
    margin-bottom: -2rem;
  }
  
  .SentenceContainer p {
    font-size: 3rem;
    margin-right: 30rem;
  }
}

/* Media query for screens 786px and below */
@media screen and (max-width: 786px) {
  .AllIntroTemplate {
    height: 50vh; /* Reduce height for smaller screens */
    margin-top: 4rem;
  }

  .ContentContainer {
    padding: 50px;
    margin-left: -1rem;
  }
  
  .TitleContainer h1 {
    font-size: 0.8rem;
    margin-bottom: -1rem;
  }
  
  .SentenceContainer p {
    font-size: 2rem;
    margin-right: 0;
    line-height: 1.4;
  }
}
