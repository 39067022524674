.all-projects-container {
  width: 90%;
  margin: 0 auto;
  padding: 1rem 0;
}

/* Container for all projects */
.projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center align projects */
  gap: 20px; /* Adjusted gap for better spacing */
  max-width: 1600px; /* Prevents items from stretching too wide */
  margin: 0 auto;
}

/* Individual project card */
.project-card {
  position: relative;
  width: calc(33.33% - 20px); /* Ensures 3 cards per row with proper spacing */
  max-width: 500px; /* Prevents overly large cards */
  height: 800px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.project-link {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.project-background {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 0;
  transition: transform 0.3s ease, filter 0.3s ease;
  transform: scale(0.9);
}

.project-card:hover .project-background {
  transform: scale(1);
}

/* Dark overlay to darken the background without affecting the overlay content */
.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Adjust opacity for desired darkness */
  z-index: 1; /* Place behind the content but over the image */
  pointer-events: none; /* Ensure it doesn't block interactions */
}

.project-info-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: left;
  color: white;
  z-index: 2; /* Higher than the overlay */
}


/* Initially only show address and name */
.project-info-overlay p,
.project-info-overlay h3 {
  opacity: 1;
  transition: opacity 0.3s ease;
}

/* Hide extra info by default */
.project-info-overlay .extra-info {
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Show extra info on hover */
.project-card:hover .extra-info {
  opacity: 1;
}

/* Project name and address styling */
.project-info-name {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.project-info-address {
  margin: 5px 0;
  font-size: 15px;
}

/* Line below name and location */
.project-info-overlay hr {
  margin: 10px auto;
  border: none;
  border-bottom: 1px solid white;
  width: 0;
  transition: width 0.9s ease-in-out;
}

/* Expand line from center on hover */
.project-card:hover .project-info-overlay hr {
  width: 90%;
  margin-left: -0.1rem;
}

/* No results message */
.no-results {
  font-size: 18px;
  color: #888;
  text-align: center;
  padding: 2rem;
}

/* Container for type and status */
.project-status-container {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 90%; /* Ensure the container spans the width */
  justify-content: space-between; /* Align items to left and right */
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 2;
  transition: opacity 0.3s ease;
}

/* Status aligned to the right (as per your original setup) */
.project-status {
  margin: 0;
  font-size: 25px;
  text-transform: uppercase;
  text-align: right;
  opacity: 1;
  transition: transform 0.7s ease, text-decoration 0.3s ease; /* Adding transition for text-decoration */
}


/* Show the container on hover */
.project-card:hover .project-statustype {
  opacity: 1;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  border-radius: 50px;
  background-color: #f1f1f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  margin-bottom: 2rem;
}

.search-bar input {
  width: 100%;
  padding: 12px 50px 12px 45px; /* Adjusted padding to add space between the icon and placeholder */
  border: none;
  border-radius: 50px;
  font-size: 18px;
  outline: none;
  background-color: transparent;
}

.search-bar input::placeholder {
  color: #aaa;
  font-style: italic;
}

.search-bar::before {
  content: '';
  position: absolute;
  left: 20px;
  width: 20px;
  height: 20px;
  background: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 24 24%22 fill=%22none%22 stroke=%22%23666%22 stroke-width=%222%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22%3E%3Ccircle cx=%2211%22 cy=%2211%22 r=%228%22/%3E%3Cline x1=%2221%22 y1=%2221%22 x2=%2216.65%22 y2=%2216.65%22/%3E%3C/svg%3E') no-repeat center;
  background-size: cover;
}

.search-bar input:focus {
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.search-bar input:hover {
  background-color: #e9e9e9;
}


/* Responsive styling for screens 786px and below */
@media (max-width: 786px) {

  .all-projects-container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
  }
  

  .project-card {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }

  .project-info-name{
    margin-right: 1.4rem;
  }

  /* Search bar */
  .search-bar {
    width: 90%; /* Make the search bar full width */
    max-width: 100%; /* Remove max-width restriction */
    margin-top: -4rem;
  }

  .search-bar input {
    padding: 12px 50px 12px 45px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Decrease font size */
  }

  .search-bar::before {
    left: 15px; /* Adjust icon position */
    width: 18px; /* Resize icon */
    height: 18px;
  }

  .search-bar input:focus {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3); /* Subtle focus effect */
  }
}


