.MobileNavBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--black);
  color: var(--black);
  z-index: 1100;
}

.MobileNavBarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 30px;
  
  background-color: var(--white);
  z-index: 1100;
  position: relative;
}

.MobileLogoImage {
  width: 50px;
  height: auto;
  z-index: 1100;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.MobileMenuIcon {
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  z-index: 1100;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.MenuIconTransition {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.MenuIconTransition .bar {
  width: 24px;
  height: 3px;
  background-color: var(--black);
  transition: transform 0.5s ease, opacity 0.5s ease;
  border-radius: 30px;
}

.MenuIconTransition.open .bar1 {
  transform: rotate(50deg);
  transform-origin: top left;
  width: 28px;
}

.MenuIconTransition.open .bar2 {
  transform: rotate(45deg);
  opacity: 0;
  transform-origin: center;
  width: 0;
}

.MenuIconTransition.open .bar3 {
  transform: rotate(-50deg);
  transform-origin: bottom left;
  width: 28px;
}

.MobileNavBarList {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding: 0;
  transition: transform 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  list-style: none;
  margin: 0;
  z-index: 1000;
  margin-top: 4rem;

}

.MobileNavBarList.show {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.MobileNavBarItem {
  margin: 20px 0;
  width: 100%; /* Ensure full width for each menu item */
  margin-left: 3.7rem;
margin-bottom: -1rem;
}

.MobileNavBarLink, .MobileNavBarLinkWithIcon {
  color: var(--black);
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  padding: 20px;
  display: block;
  width: 100%; /* Ensure the link takes up full width */
  text-align: left; /* Align text to the left */
  
}

.MobileNavBarLinkWithIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
  width: 100%;
}

.MobileMenuIcon-ChevronIcon {
  margin-left: auto; /* Pushes the icon to the right */
  margin-right: 10rem; /* Ensures the icon has a right margin of 5rem */
  transition: transform 0.3s ease;
}

.MobileMenuIcon-rotate-up {
  transform: rotate(135deg);
}


.MobileSubMenuList {
  overflow: hidden;
  max-height: 0; /* Initially collapsed */
  opacity: 0; /* Initially invisible */
  transition: max-height 0.5s ease, opacity 0.5s ease; /* Smooth transition */
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  width: 100%;
  background-color: var(--white);
  padding-left: 20px;
  margin: 0;
}

.MobileSubMenuList.show {
  max-height: 500px; /* Adjust this value based on expected submenu size */
  opacity: 1; /* Fully visible */
}

/* Optional: Adjust transition for smaller items */
.MobileSubMenuItem {
  width: 100%;
  margin: 10px 0;
}

.MobileSubMenuLink {
  color: var(--black);
  text-decoration: none;
  font-size: 12px;
  padding: 10px;
  width: 100%;
  display: block;
  text-align: left;
}

.MobileSubMenuLink:hover {
  color: var(--red);
}



.MobileNavBarItem, .MobileSubMenuItem {
  position: relative; 
  padding-bottom: 10px; 
}

.MobileNavBarItem::after, .MobileSubMenuItem::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70%;
  height: 1px;
  background-color: #cecece;
}

.MobileNavBarItem:last-child::after, 
.MobileSubMenuItem:last-child::after {
  content: none; 
}


