/* General styling for the admin panel */
.admin-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa; /* Light gray background */
  min-height: 100vh;
}

/* Card container styling */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 80%; /* Limit the width */
  padding: 10px;
}

/* Individual card styling */
.card-box {
  background: #ffffff; /* White background for cards */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 8px; /* Rounded corners */
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

/* Hover effect for cards */
.card-box:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Title styling */
.card-box h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #343a40; /* Dark gray for text */
  margin-bottom: 10px;
}

/* Details text styling */
.card-box p {
  font-size: 1rem;
  color: #6c757d; /* Medium gray for text */
  margin: 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .card-box {
    padding: 15px;
  }

  .card-box h3 {
    font-size: 1.2rem;
  }

  .card-box p {
    font-size: 0.9rem;
  }
}
