/* Container for the whole carousel */
.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%; /* Adjusted for responsive width */
  max-width: 1700px;
  margin: 0 auto 3rem auto; /* Horizontally center the carousel */
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
}

/* Header with title, link, and buttons */
.carousel-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
  position: relative;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.carousel-title {
  font-size: 1.6rem;
  color: #666;
  margin-right: 2rem;
  text-transform: uppercase;
}

.carousel-link {
  font-size: 1rem;
  margin-left: 15px;
  color: var(--black);
  text-decoration: none;
  display: flex;
  align-items: center; /* Align text and icon */
  transition: color 0.3s ease;
}

.carousel-link:hover {
  color: var(--red);
}

.iconLinkCarousel {
  margin-left: 8px; /* Initial gap between text and icon */
  font-size: 15px;
  transition: margin-left 0.3s ease; /* Smooth transition for margin change */
}

.carousel-link:hover .iconLinkCarousel {
  margin-left: 15px; /* Increased gap on hover */
}

/* Buttons to navigate through the carousel */
.carousel-buttons {
  display: flex; /* Ensure buttons are in line with other header items */
  margin-left: auto;
  margin-top: 10px; /* Space above buttons on smaller screens */
}

.carousel-button {
  background-color: var(--black);
  width: 50px;
  height: 50px;
  margin-left: 10px;
  border-radius: 50%;
  color: var(--white);
  padding: 10px;
  cursor: pointer;
  z-index: 500;
  transition: background-color 0.3s;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Wrapper that contains the carousel */
.carousel-wrapper {
  overflow: hidden;
  width: 100%;
}

/* The carousel itself */
.carousel {
  display: flex;
  transition: transform 0.3s ease;
  gap: 20px;
  width: auto; /* Allow the carousel to expand with the number of cards */
}


/* Individual card styles */
.carousel-card {
  flex: 0 0 auto; /* Prevent cards from shrinking or growing */
  width: 100%; /* Full width for one card at a time on mobile */
  max-width: 350px; /* Set a max width */
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

/* Container for the video */
.video-container {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust as needed */
  z-index: 1;
}

.overlay {
  z-index: 2;
}

/* Video default state: paused */
.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none; /* Prevent interaction */
}

/* When hovering on the card, play the video */
.carousel-card .background-video {
  pointer-events: none; /* Disable pointer events by default */
}

.carousel-card:hover .background-video {
  pointer-events: auto; /* Enable pointer events on hover */
}

/* Overlay styles */
.overlay {
  position: absolute;
  bottom: 0; /* Aligns the overlay to the bottom of the card */
  left: 0;
  width: 100%;
  height: 50%; /* Adjust height as needed */
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
  pointer-events: none; /* Disable pointer events for the overlay */
}

.carousel-card:hover .overlay {
  opacity: 1;
  pointer-events: none; /* Keep overlay non-interactive */
}

.card-title {
  font-size: 20px; /* Adjusted font size for mobile */
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0;
}

.card-details {
  font-size: 0.8em; /* Adjust font size for details */
  color: #ddd;
  margin: 0 5px;
  padding: 20px;
}

.card-photo-container {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust as needed */
  z-index: 1;
}

.card-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 3s ease-in-out; /* Smooth zoom transition */
}

.card-photo-container:hover .card-photo {
  transform: scale(1.3); /* Scale the image to 110% on hover */
}


/* Darken the image or video when overlay is visible */
.card-photo-container,
.video-container {
  position: relative;
  overflow: hidden;
}

.card-photo-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Initially transparent */
  transition: background-color 0.3s ease; /* Smooth transition for dark overlay */
  z-index: 1; /* Ensure the dark overlay is above the image/video but below the text */
}

.card-photo-container:hover::before {
  background-color: rgba(0, 0, 0, 0.2); /* Darken the image/video on hover */
}

.card-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 3s ease-in-out; /* Smooth zoom transition */
  z-index: 0; /* Ensure the image is below the dark overlay */
}

.card-photo-container:hover .card-photo {
  transform: scale(1.3); /* Scale the image on hover */
}



/* Add the line under the title */
.card-title {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0;
  text-align: center;
}

/* Line that starts from the center and expands on hover */
.card-title::after {
  content: '';
  position: absolute;
  bottom: -5px; /* Position it just below the title */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background-color: var(--white);
  transition: width 2s ease; /* Smooth animation of the line */
}

/* When hovering over the image or video, the line expands */
.carousel-card:hover .card-title::after {
  width: 100%;
}



/* Styles for screens 786px and smaller */
@media (max-width: 786px) {
  .carousel-container {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1.5rem; /* Adjust spacing for smaller screens */
  }

  .carousel-wrapper {
    overflow: hidden;
    width: 100%;
  }

  .carousel {
    display: flex;
    transition: transform 0.3s ease;
    gap: 20px; /* Add gap between cards */
  }

  .carousel-card {
    flex: 0 0 100%; /* Ensure full width for one card */
    max-width: 96%; /* Full width */
    margin-bottom: 1rem;
  }

  .video-container,
  .card-photo-container {
    height: 600px; /* Set equal height for both video and photo */
    
  }

  .overlay {
    height: 40%; /* Adjust overlay height for smaller screens */
    bottom: 0;
    opacity: 1;
    padding-bottom: 10px;
  }

  .card-title {
    font-size: 1.2rem; /* Adjust title size */
  }

  .card-details {
    font-size: 0.8em;
    margin: 0 5px;
    padding: 10px;
  }

  .card-title::after {
    bottom: -5px;
  }

  .card-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .carousel-card:hover .background-video,
  .card-photo-container:hover .card-photo {
    transform: scale(1.1); /* Scale a little less on smaller screens */
  }

  .carousel-title{
    font-size: 2rem;
    margin-left: 0.7rem;
    margin-top: 5rem;
  }
}
