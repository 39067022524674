/* General Table Styling */
.product-table {
  width: 80%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

/* Header Styling */
.product-table thead {
  background-color: #2c3e50;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
}

.product-table th,
.product-table td {
  padding: 15px 20px;
  border-bottom: 1px solid #e0e0e0;
}

/* Zebra Stripes */
.product-table tr:nth-child(even) {
  background-color: #f7f9fc;
}



/* Buttons */
.edit-btn,
.delete-btn {
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.edit-btn {
  background-color: #28a745;
  color: white;
  box-shadow: 0 4px 6px rgba(40, 167, 69, 0.3);
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  box-shadow: 0 4px 6px rgba(220, 53, 69, 0.3);
}

.edit-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.delete-btn:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

/* Admin Icon */
.admin-icon {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.admin-icon svg {
  color: #2c3e50;
  cursor: pointer;
  transition: color 0.3s ease;
}

.admin-icon svg:hover {
  color: #3498db;
}

.delete-selected-btn {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.delete-selected-btn:hover {
  background-color: #c9302c;
}

.product-table th input[type='checkbox'] {
  cursor: pointer;
}

.product-table td input[type='checkbox'] {
  cursor: pointer;
}


/* Responsive Styles */
@media (max-width: 786px) {
  .product-table thead {
    display: none; /* Hide headers for mobile */
  }

  .product-table,
  .product-table tbody,
  .product-table tr,
  .product-table td {
    display: block;
    width: 100%;
  }

  .product-table tr {
    margin-bottom: 15px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .product-table td {
    text-align: left;
    padding-left: 50%;
    position: relative;
    border-bottom: none;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    padding: 12px 15px;
  }

  .product-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    width: calc(50% - 30px); /* Adjust width for spacing */
    font-weight: bold;
    color: #2c3e50;
    font-size: 14px;
  }

  .product-table td img {
    max-width: 80px;
    height: auto;
    margin: 10px 0;
  }

  .edit-btn,
  .delete-btn {
    width: 100%;
    margin-bottom: 10px;
    padding: 12px 0;
  }

  .edit-btn {
    margin-top: 5px;
  }

  .delete-btn {
    margin-top: 0;
  }
}
