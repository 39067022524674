.NewsRoomList-Container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}
  
.NewsRoomList-AllCards {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    max-width: 70%; /* Adjust as needed */
}
  
.NewsRoomList-Card {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    transition: box-shadow 0.3s ease;
    background-color: #fff;
}
  
.NewsRoomList-CardLeft {
    width: 40%;
}
  
.NewsRoomList-CardRight {
    width: 60%;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space between items */
    padding: 20px; /* Add padding for spacing */
}
  
.NewsRoomList-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
}
  
.NewsRoomList-Date {
    color: #777;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
}
  
.NewsRoomList-Title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #000;
}
  
.NewsRoomList-Description {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 300;
    color: #666;
}

/* Media Query for 786px */
@media (max-width: 786px) {
    .NewsRoomList-Container {
        padding: 1px;
        margin-top: 4rem;
    }

    .NewsRoomList-AllCards {
        gap: 30px;
        max-width: 100%;
    }

    .NewsRoomList-Card {
        flex-direction: column;
    }

    .NewsRoomList-CardLeft,
    .NewsRoomList-CardRight {
        width: 100%;
        margin-left: 0;
    }

    .NewsRoomList-CardRight {
        padding: 1px;
        margin: 0 auto;
        justify-content: center;
    }

    .NewsRoomList-image {
        height: 250px;
    }

    .NewsRoomList-Date {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .NewsRoomList-Title {
        font-size: 20px;
        margin-bottom: 5px;
    }

    .NewsRoomList-Description {
        font-size: 14px;
        margin-bottom: 10px;
    }
}

/* Media Query for 1300px */
@media (max-width: 1300px) {
    .NewsRoomList-AllCards {
        gap: 40px;
        max-width: 80%;
    }

    .NewsRoomList-CardRight {
        margin-left: 20px;
        padding: 15px;
    }

    .NewsRoomList-image {
        height: 300px;
    }
}
