.loading-icon {
  position: fixed; /* Overlay the entire page */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1); /* White background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it overlays all content */
}

.loading-circle {
  width: 150px;
  height: 150px;
  border: 5px solid rgba(0, 0, 0, 0.0); 
  border-top: 5px solid #0098da; /* Solid blue color for the spinning effect */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Smooth spinning animation */
}

.loading-logo {
  position: absolute;
  width: 80px; /* Adjust size as needed */
  height: 100px;
  z-index: 10000; /* Ensure it is above the spinner */
}
  
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
