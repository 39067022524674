.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #000;
    color: #fff;
    padding: 16px 20px;
    border-radius: 6px;
    box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2);
    text-align: center;
    width: 90%;
    max-width: 1000px;
    font-size: 18px;
    font-family: Arial, sans-serif;
    z-index: 1000;
}

.cookie-consent a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid white;
    transition: border-bottom 0.3s;
}

.cookie-consent a:hover {
    border-bottom: 1px solid transparent;
}

.cookie-buttons {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.cookie-buttons button {
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 1rem;
}

.accept-btn {
    background-color: white;
    color: black;
    border: 1px solid white;
}

.accept-btn:hover {
    background-color: black;
    color: white;
    border: 1px solid #fff;
}

.decline-btn {
    background-color: black;
    color: white;
    border: 1px solid white;
}

.decline-btn:hover {
    background-color: #000;
    color: #fff;
    border: 1px solid #fff;
}


@media (max-width: 600px) {
    .cookie-consent {
        width: 95%;
        max-width: 360px;
        font-size: 16px;
        padding: 14px;
    }

    .cookie-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .cookie-buttons button {
        width: 100%;
        font-size: 16px;
        padding: 12px;
    }
}