.popup-ad-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Ensures the popup is above other elements */
}

.popup-ad-content {
  position: relative;
  background-color: white;
  border-radius: 8px;
  display: inline-block; /* Adjusts based on content */
  overflow: hidden;
}

.popup-ad-image {
  max-width: 100%; /* Ensure the image width fits within the container */
  max-height: 100vh; /* Ensure the image height fits within the viewport */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures the entire image is shown */
}

.popup-close-icon {
  position: absolute;
  top: -10px; /* Adjust this value to move the icon just above the ad */
  right: 10px; /* Aligns the icon to the right */
  font-size: 50px;
  cursor: pointer;
  color: var(--red);
  padding: 5px; /* Adds space around the icon */
}
