.project-about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: auto;
  max-width: 1600px;
  margin: 2rem auto;
  text-align: center;
  flex-direction: column;
  gap: 1rem; /* Even spacing between children */
}

.project-about-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem; /* Adds consistent vertical spacing */
}

.project-logo {
  max-width: 300px;
  height: auto;
}

.project-address,
.project-about-text,
.project-rera,
.project-quote {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

.project-address {
  font-size: 0.9rem;
  line-height: 1.2;
  color: #333;
  text-transform: uppercase;
  font-weight: bold;
}

.project-about-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  letter-spacing: 0.1rem;
  font-weight: 500;
}

.project-rera {
  font-size: 1.2rem;
  letter-spacing: 0.3rem;
  line-height: 1.5;
  color: #333;
  font-weight: 700;
}

.project-quote {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #000;
  font-weight: bold;
  letter-spacing: 0.7px;
}

@media (max-width: 786px) {
  .project-about-container {
    padding: 1rem;
    margin: 1.5rem auto;
    gap: 0.8rem;
  }

  .project-about-left {
    padding: 0 1rem;
    gap: 1rem;
    width: 100%;
  }

  .project-logo {
    max-width: 160px;
    height: auto;
  }

  .project-address {
    font-size: 0.75rem;
    line-height: 1.4;
    margin-bottom: 0.5rem;
    letter-spacing: 0.05rem;
  }

  .project-quote {
    font-size: 1.1rem;
    line-height: 1.3;
    letter-spacing: 0.4px;
    font-weight: 600;
    padding: 0 0.5rem;
  }

  .project-about-text {
    font-size: 0.85rem;
    line-height: 1.6;
    letter-spacing: 0.03rem;
    padding: 0 0.5rem;
  }

  .project-rera {
    font-size: 0.95rem;
    line-height: 1.4;
    letter-spacing: 0.15rem;
    padding: 0 0.5rem;
  }
}

