.HomeIntroContainer {
    width: 100%;
    height: 105vh; /* Full screen height */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-top: -6.3rem;
    transition: transform 5s ease-in-out;
  }
  
  .HomeIntroContent {
    display: flex;
    justify-content: space-between;
    width: 70%; /* Adjust width as necessary */
    color: var(--white);
    z-index: 1; /* Make sure content stays above the background */
  }
  
  .HomeIntroLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 40%;

    
  }
  
  .HomeIntroCompany {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .HomeIntroValues {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1.5rem 0;
  }
  
  .HomeIntroValues li {
    font-size: 6rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  
  .HomeIntroRight {
    max-width: 40%;
    display: flex;
    align-items: center;
  }
  
  .HomeIntroSentence {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-top: 35rem;
  }
  
  .HomeIntroContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: to make text more readable */
    z-index: 0; /* Below content */
  }
  

  @media (max-width: 786px) {
    .HomeIntroContainer {
      height: 80vh; /* Adjust height for smaller screens */
      margin-top: 0; /* Adjust margin if necessary */
      padding: 20px; /* Add padding for better spacing */
      display: flex;
      justify-content: center;
      align-items: flex-start; /* Align content at the top */
      width: auto;
    }
  
    .HomeIntroContent {
      flex-direction: column; /* Stack content vertically */
      justify-content: flex-start; /* Align content to the start */
      align-items: flex-start; /* Align items to the left */
      width: 90%; /* Reduce width for smaller screens */
      text-align: left; /* Ensure text is aligned to the left */
      margin-left: -2rem;
    }
  
    .HomeIntroLeft {
      align-items: flex-start; /* Align items to the left */
      max-width: 100%; /* Full width for smaller screens */
      margin-left: 0; /* Remove margin */
      margin-bottom: 2rem; /* Add margin below for spacing */
      margin-top: 10rem;
    }
  
    .HomeIntroCompany {
      font-size: 0.8rem; /* Increase font size for better readability */
      margin-bottom: 1.5rem; /* Adjust margin */
    }
  
    .HomeIntroValues li {
      font-size: 2.7rem; /* Reduce font size for smaller screens */
      font-weight: 500;
      margin-bottom: 1rem; /* Adjust margin */
      text-align: left; /* Ensure the text within the list items is left-aligned */
    }
  
    .HomeIntroLink {
      font-size: 0.9rem; /* Adjust font size */
      gap: 5px; /* Default gap between text and icon */
      margin-top: 1.5rem; /* Adjust margin */
      text-align: left; /* Align link text to the left */
    }
  
    .HomeIntroRight {
      max-width: 100%; /* Full width */
      margin-top: 2rem; /* Add margin */
      text-align: left; /* Align right section text to the left */
    }
  
    .HomeIntroSentence {
      font-size: 0.9rem; /* Adjust font size */
      line-height: 1.4; /* Adjust line height */
      margin-top: 2rem; /* Adjust margin */
      text-align: left; /* Align the sentence to the left */
      margin-right: 5rem;
    }
  
    .HomeIntroContainer::before {
      background-color: rgba(0, 0, 0, 0.6); /* Slightly darker overlay for readability */
    }
  }
  