:root {
  --red: #CC0200; /* For example, red */
  --black: #000000; /* Black */
  --grey: #1A1B1F;
  --white: #fff; /* Light grey */
  --text-color: #333333; /* Dark grey */
  --link-color: #1a73e8; /* Blue for links */
  --hover-color: #FF5733; /* Hover color */
  --border-color: #e0e0e0; /* Light border */
  --button-background: #008CBA; /* Button background */
  --button-text-color: #ffffff; /* Button text color */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Home.css */
.fade-in-up {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-up.visible {
  opacity: 1;
  transform: translateY(0);
}
