/* General container styling */
.dynamic-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background: linear-gradient(135deg, #ffffff, #f9fafb);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Roboto', sans-serif;
  margin-top: 3rem;
}

/* Form title */
.dynamic-form h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
  color: #333333;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Form group styling */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 16px;
  color: #495057;
  letter-spacing: 0.5px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="number"] {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  transition: all 0.3s ease;
  background-color: #fefefe;
}

.form-group input[type="text"]:focus,
.form-group input[type="email"]:focus,
.form-group input[type="number"]:focus {
  outline: none;
  border-color: #4e73df;
  box-shadow: 0px 0px 10px rgba(78, 115, 223, 0.3);
  background-color: #f7f9fc;
}



/* Array group styling */
.array-item {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 15px;
  border: 1px solid #e9ecef;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.array-item .remove-btn {
  background-color: #e74a3b;
  color: #ffffff;
  border: none;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: background-color 0.3s ease;
}

.array-item .remove-btn:hover {
  background-color: #c9302c;
}

/* Add item button (styled as div) */
.add-item {
  display: inline-block;
  background-color: #1cc88a;
  color: #ffffff;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
  text-align: center;
}

.add-item:hover {
  background-color: #17a673;
}

/* Remove item button (styled as div) */
.remove-item {
  display: inline-block;
  background-color: #e74a3b;
  color: #ffffff;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
  text-align: center;
}

.remove-item:hover {
  background-color: #c9302c;
}


/* Submit button */
.buttoneditPage {
  position: fixed;
  bottom: 4rem;
  right: 10rem;
  z-index: 1000;
}

.buttoneditPage button {
  background: linear-gradient(135deg, #4e73df, #224abe);
  color: #ffffff;
  border: none;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  transform-origin: center;
}

.buttoneditPage button:hover {
  background: linear-gradient(135deg, #3751d6, #1a306c);
  transform: scale(1.05);
}

.buttoneditPage button:disabled {
  background-color: #d1d1d1;
  cursor: not-allowed;
}

/* Success message */
.success-message {
  margin-top: 20px;
  font-size: 16px;
  color: #1cc88a;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .dynamic-form {
    padding: 20px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input {
    font-size: 14px;
    padding: 10px;
  }

  button[type="button"] {
    font-size: 13px;
    padding: 8px 12px;
  }

  .buttoneditPage {
    bottom: 2rem;
    right: 2rem;
  }

  .buttoneditPage button {
    font-size: 14px;
    padding: 10px 18px;
  }

  .array-item {
    padding: 12px;
  }

  .array-item .remove-btn {
    padding: 5px;
    font-size: 10px;
  }
}
