.project-floorplan-container {
    text-align: center;
    padding: 2rem;
    width: auto;
    margin: 0 auto;
    background-color: var(--grey);
  }
  
  .project-floorplan-title {
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    margin-bottom: 2rem;
    color: #fff;
  }
  
  .floorplan-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 images per row on larger screens */
    gap: 1rem;
    margin-top: 1rem;
    width: 60%;
    margin: 0 auto;
  }
  
  .floorplan-image {
    width: 100%;
    height: 300px;
    cursor: pointer;
    transition: transform 0.3s;
    margin-bottom: 3rem;
    filter: blur(8px);
  }
  
  .floorplan-image:hover {
    transform: scale(1.01);
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    max-width: 50%;
    max-height: auto;
  }
  
/* Add zoom effect for modal image */
.modal-image {
  width: 50%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  cursor: zoom-in;
}

.modal-image.zoomed {
  transform: scale(3); /* Adjust scale value as needed */
  cursor: zoom-out;
}

  
  .modal-navigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%;
    padding: 0 1rem;
  }
  
  .nav-icon {
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }
  
  .close-icon {
    position: absolute;
    top: 1rem; /* Adjust as needed */
    right: 1rem; /* Adjust as needed */
    font-size: 2rem; /* Smaller to fit within content */
    color: white;
    cursor: pointer;
  }
  
  


  .download-form input {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
  }
  
  

  
  /* Responsive design for screens with a max-width of 786px */
  @media (max-width: 786px) {
    .project-floorplan-container {
      padding: 1rem;
      margin-top: 3rem;
      width: auto;
    }
  
    .project-floorplan-title {
      font-size: 16px;
      letter-spacing: 0.3rem;
      margin-bottom: 1.5rem;
    }
  
    .floorplan-grid {
      grid-template-columns: repeat(1, 1fr); /* Display one image per row */
      width: 100%; /* Adjust the width for smaller screens */
      gap: 0.5rem;

    }
  
    .floorplan-image {
      height: 200px; /* Adjust image height for smaller screens */
      margin-bottom: 2rem;

    }
    .modal-navigation{
        padding: 1rem;
        margin-left: -1rem;
        top: 40%;
    }
  
    .modal-image {
      width: 100%; /* Adjust modal image width for smaller screens */
    }
  
    .close-icon {
      top: -5rem;
      right: 0.5rem;
      font-size: 2.5rem;
    }
  
    .download-btn {
      padding: 0.8rem 1.5rem; /* Adjust padding for the button */
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  }


  /* Responsive design for screens smaller than or equal to 786px */
@media screen and (max-width: 786px) {
  .modal-content {
    width: 70%; /* Use a percentage-based width for smaller screens */
    padding: 1rem; /* Reduce padding for smaller screens */
    margin: 0 auto;
  }

  .modal-image {
    width: 80%;
    height: auto;
    
  }

  .download-form input {
    padding: 0.5rem; /* Adjust input padding for smaller screens */
  }

  
}
  