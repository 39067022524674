.services-title-container {
    padding: 2rem;
    width: 70%;
    margin: 2rem auto;
    margin-top: 5rem;
  }
  
  .services-main-content {
    margin-bottom: 2rem;
  }
  
  .services-title {
    font-size: 0.8rem;
    margin-bottom: -1rem;
    text-transform: uppercase;
  }
  
  .services-header {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #000;
  }
  
  .services-sentence {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 2rem;
    color: #3b3b3b;
    margin-top: 2rem;
  }
  
  .services-sentence p {
    margin-bottom: 1rem; /* Add space between sentences */
  }
  
  
  .services-contact {
    display: flex;
    align-items: center;
  }
  
  .phone-title {
    font-size: 1.2rem;
    margin-right: 1rem;
    color: var(--primary-color);
  }
  
  .phone-details {
    display: flex;
    align-items: center;
  }
  
  .phone-icon {
    margin-right: 0.5rem;
    color: #000; /* Default icon color */
  }
  
  .phone-number {
    font-size: 1.2rem;
    text-decoration: none;
    color: #000; /* Default color */
    transition: color 0.3s ease; /* Smooth transition on hover */
  }
  
  .phone-details:hover .phone-number,
  .phone-details:hover .phone-icon {
    color: red; /* Change both number and icon color on hover */
  }


  @media (max-width: 786px) {
    .services-title-container {
      padding: 0.5rem;
      width: auto;
      margin: 3rem auto;
      margin-top: 3rem;
    }
  
    .services-main-content {
      margin-bottom: 1.5rem;
      width: 80%;
      margin: 0 auto;
    }
  
    .services-title {
      font-size: 0.7rem;
      margin-bottom: -0.5rem;
      text-transform: uppercase;
    }
  
    .services-header {
      font-size: 2rem;
      margin-bottom: 0.5rem;
      color: #000;
    }
  
    .services-sentence {
        font-size: 1rem;
        line-height: 2; /* Increase the line-height for more spacing between lines */
        margin-bottom: 1rem; /* Reduce bottom margin to ensure uniform spacing */
        color: #3b3b3b;
        margin-top: 1.5rem;
      }
      
      .services-sentence p {
        margin: 0; /* Remove default margins from individual paragraphs */
        padding-bottom: 1rem; /* Add equal space between paragraphs */
      }
      
  
    .services-sentence p {
      margin-bottom: 0.8rem;
    }
  
    .services-contact {
      display: flex;
      align-items: left;
      flex-direction: column; /* Stack the phone details vertically on smaller screens */
    }
  
    .phone-title {
      font-size: 1rem;
      margin-right: 0;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
    }
  
    .phone-details {
      display: flex;
      align-items: center;
      justify-content: center; /* Center align the icon and number */
    }
  
    .phone-icon {
      margin-right: 0.3rem;
      color: #000; /* Default icon color */
      font-size: 1.2rem; /* Adjust icon size */
    }
  
    .phone-number {
      font-size: 1rem;
      text-decoration: none;
      color: #000; /* Default color */
      transition: color 0.3s ease; /* Smooth transition on hover */
    }
  
    .phone-details:hover .phone-number,
    .phone-details:hover .phone-icon {
      color: red; /* Change both number and icon color on hover */
    }
  }
  