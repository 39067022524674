/* General Footer Styling */
/* General Footer Styling */
.Footer {
    font-family: "Montserrat", Sans-serif;
    color: #000;
  }
  
  .Footer-Above {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #F2F2F2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    height: 550px;
  }
  
  .Footer-Above::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    z-index: -1;
  }
  
  .Footer-AboveContainer {
    width: 70%; /* Set the width to 70% */
    margin: 0 auto; /* Center the container horizontally */
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-top: 2rem;
  }
  
  
  
  .Footer-Explore,
  .Footer-Contact,
  .Footer-Form {
    flex: 1;
    margin: 0 30px;
  }
  
  .Footer-ExploreHeader {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  
  
  .Footer-NavData {
    display: block; /* Ensures the entire area is clickable */
    font-size: 14px;
    font-weight: 550;
    padding: 15px;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .Footer-NavItem:not(.has-submenu) .Footer-NavData:hover {
    background-color: #000; /* Background changes on hover */
    color: var(--white);
  }

  /* Footer Submenu Styles */
.Footer-NavList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .Footer-NavItem {
    margin: 10px 0;
  }
  
  .Footer-NavLinkWithIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .Footer-NavLinkWithIcon:hover{
    background-color: #000; /* Background changes on hover */
    color: var(--white);
  }

/* Hover effect only when hovering over the parent container (Footer-NavLinkWithIcon) */
.Footer-NavLinkWithIcon:hover .Footer-NavData {
    background-color: #000; /* Background changes on hover */
    color: var(--white);
  }
  
  /* Ensure Footer-NavData itself has no background change unless hovering over parent */
  .Footer-NavData {
    background-color: transparent;
    color: #000;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  
  .Footer-Icon {
    font-size: 12px;
    margin-right: 20px;
    transition: transform 0.3s ease;
  }
  
  .Footer-Icon-rotate-up {
    transform: rotate(135deg);
  }
  
  .Footer-SubMenuList {
    list-style: none;
    margin: 0;
    padding-left: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  
  .Footer-SubMenuList.show {
    max-height: 500px; /* Adjust this value based on the content */
  }
  
  .Footer-SubMenuItem {
    margin: 15px 0;
  }
  
  .Footer-SubMenuLink {
    color: #000;
    text-decoration: none;
    font-size: 14px;
  }
  
  .Footer-SubMenuLink:hover {
    color: #cc0200;
  }
  
  
  /* Footer Contact Section */
  .Footer-HeaderContact {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  
  .Footer-ContactLogos {
    display: flex;
    gap: 30px;
    margin: 20px 0;
  }
  
  
  .Footer-SocialIcon {
    color: #2f2f2f;
    font-size: 20px;
    transition: color 0.3s ease;
  }
  
  .Footer-SocialIcon:hover {
    color: #CC0200;
  }
  
  /* Contact Information Styles */
.Footer-ContactInfo {
    margin-bottom: 2rem;
  }

  .Footer-ContactTitle{
    font-size: 0.8rem;
    font-weight: 700;
    margin-right: 0.3rem;
  }
  
  .Footer-ContactDetails {
    font-size: 15px;
    font-weight: 400;
    color: #000;
    padding-bottom: 20px;
  }
  
  .Footer-ContactEmail {
    color: #000;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .Footer-ContactEmail:hover {
    color: #CC0200;
  }
  
  /* Footer Form Section */
  
  .Footer-HeaderForm{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 25px;
  }
  
  
  .Footer-FormBox {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }
  
  .Footer-FormGroup {
    display: flex;
    flex-direction: column;
  }
  
  .Footer-FormInput {
    padding: 15px;
  }
  
  .Footer-SubmitButton {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .Footer-SubmitButton:hover {
    background-color: #555;
  }
  
  /* Footer Below Section */
  .Footer-Below {
    background-color: #1C1C1C; /* Bottom section color */
    padding: 20px;
    padding-right: 20rem;
    padding-left: 20rem;
    height: 130px;
    
  }
  
  .Footer-Policy {
    display: flex;
    margin-top: 1rem;
    padding-bottom: 15px;
    border-bottom: 1px solid #555; /* Dividing line color */
  }
  
  .Footer-Policy p {
    color: #fff;
    margin: 0;
    margin-right: 15px;
    position: relative;
  }
  
  .Footer-Policy p:not(:last-child)::after {
    content: "|";
    position: absolute;
    right: -10px;
    color: #fff; /* Color of the separator */
  }
  
  .Footer-PolicyLink {
    font-size: 14px;
    color: inherit; /* Inherit the text color */
    text-decoration: none; /* Remove underline */
  }
  
  .Footer-PolicyLink:hover {
    text-decoration: underline; /* Optional: Add underline on hover if needed */
  }
  
  .Footer-BelowLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .Footer-AllRights {
    margin: 0;
    font-size: 14px;
    margin-bottom: 7px;
    color: #fff;
  }
  
  .Footer-PolicyPrivacy {
    margin: 0;
    font-size: 14px;
    color: #fff;
  }
  
  .Footer-PolicyPrivacyLink {
    color: inherit; /* Inherit the text color from the parent element */
    text-decoration: none; /* Remove the underline */
  }
  
  .Footer-PolicyPrivacyLink:hover {
    text-decoration: underline; /* Optional: Add underline on hover if desired */
  }
  
  /* Ensure no color is applied to visited and active link states */
  .Footer-PolicyPrivacyLink:visited,
  .Footer-PolicyPrivacyLink:active {
    color: inherit;
  }
  
  .Footer-Logo {
    text-align: right;
  }
  
  .Footer-LogoImage {
    height: 80px;
    margin-top: 1rem;
  }
  

  /* Scroll to Top Button */
.ScrollToTopButton {
    position: fixed;
    bottom: 40px;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    transition: opacity 0.3s ease;
    z-index: 1000; /* Ensure the button appears above other elements */
  }
  
  .ScrollToTopButton:hover {
    background-color: #444;
  }
  
  /* Hide the button initially */
  .ScrollToTopButton.hidden {
    opacity: 0;
    pointer-events: none;
  }
  



  
  
  /* Media query for screens with a max width of 786px */
  @media (max-width: 1300px) {
    .Footer-Above {
      flex-direction: column;
      height: auto;
      padding: 10px;
      align-items: left;
  
    }
  
    .Footer-AboveContainer {
      padding: 10px; /* Reduce padding for smaller screens */
      margin-top: 1rem;
      flex-direction: column;
    }
  
    .Footer-Explore,
    .Footer-Form {
      margin: 10px 0; /* Reduce margin between sections */
      flex: 1 1 100%; /* Make each section take up full width */
    }
  
    .Footer-Contact{
      margin: 10px 0; /* Reduce margin between sections */
      flex: 1 1 100%; /* Make each section take up full width */
      display: flex;
      flex-direction: column; /* Stack the contents vertically */
      align-items: center; /* Center the content horizontally */
      text-align: center; /* Ensure all text is centered */
    }
  
    .Footer-ExploreHeader,
    .Footer-HeaderContact,
    .Footer-HeaderForm {
      font-size: 24px; /* Decrease header font size */
      text-align: left; /* Center the section headers */
    }
  
    .Footer-NavData {
      font-size: 12px; /* Decrease font size for links */
      padding: 10px; /* Reduce padding for smaller screen */
      text-align: left; /* Center the footer links */
    }
  
    .Footer-ContactLogos {
      justify-content: center; /* Center the social icons */
      gap: 50px; /* Reduce the gap between social icons */
    }
  
  
  
    /* Footer Below Section */
    .Footer-Below {
      padding: 10px; /* Adjust padding for smaller screens */
      height: auto; /* Let the height adjust based on content */
      text-align: left; /* Center the footer below content */
    }
  
    .Footer-Policy {
      flex-direction: column; /* Stack policies vertically */
      align-items: left; /* Center align the text */
      margin-top: 10px;
    }
  
    .Footer-Policy p {
      margin-right: 0; /* Remove right margin for stacked layout */
      margin-bottom: 5px; /* Add spacing between policy items */
    }
  
    .Footer-BelowLine {
      margin-top: 2rem;
      flex-direction: column; /* Stack items in the below section vertically */
      text-align: left; /* Center align the text */
      gap: 10px; /* Add spacing between the items */
    }
  
    .Footer-AllRights,
    .Footer-PolicyPrivacy {
      font-size: 12px; /* Decrease the font size */
      margin-bottom: 5px; /* Adjust margin for spacing */
    }
  
    .Footer-Logo {
      text-align: center; /* Center the logo for small screens */
    }
  
    .Footer-LogoImage {
      height: 60px; /* Adjust logo size */
    }

    .Footer-ContactInfo{
        margin-bottom: 0rem;
    }
  }
  



  