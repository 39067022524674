.nri-section {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  width: 70%;
  margin: 5rem auto;
}

.nri-form-container, .nri-content {
  width: 50%;
  padding: 1rem;
}

.nri-form-container h2, .nri-content h2 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.nri-content h2 {
  margin-bottom: 3rem;
  margin-right: 10rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
  font-weight: bold;
  margin-top: 1rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%; /* Ensure consistent width for all form inputs */
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

textarea {
  resize: vertical;
  height: 150px;
}

.form-group select {
  background-color: #fff;
  color: #333;
  appearance: none;
  cursor: pointer;
}

.form-group select:focus {
  border-color: #007bff;
  outline: none;
}

.form-group select option {
  padding: 10px;
  font-size: 1rem;
}

.form-group select:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

.nri-content ul {
  padding: 0;
}

.nri-content li {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.phone-group {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure full width for the phone group */
}

.phone-input {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.country-code {
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  background-color: #fff;
}

#phone {
  flex-grow: 1;
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-left: none;

}

.FormTypeSubmit-btn {
  background-color: var(--grey);
  color: white;
  padding: 20px 30px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.7s ease;
  margin: 0 auto;
  align-items: center;
  border: 1px solid #fff;
}

.FormTypeSubmit-btn:hover {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.FormTypeSubmit-btn:active {
  background-color: #fff;
  transform: scale(0.98);
}

.FormTypeSubmit-btn:focus {
  outline: none;
}

.FormTypeSubmit-btn:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

  

  /* Media Query for screen widths 786px and below */
@media (max-width: 786px) {
    .nri-section {
        flex-direction: column;
        width: 80%;
        padding: 1rem;
    }

    .nri-form-container, .nri-content {
        width: 100%;
        padding: 0;
        margin: 0 auto;
    }

    .nri-form-container{
        margin-left: -1rem;
        margin-top: 3rem;

    }

    .nri-content h2 {
        margin-right: 0;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }

    .form-group input,
    .form-group textarea,
    .form-group select {
        font-size: 0.9rem;
        padding: 1rem;
    }

    textarea {
        height: 120px;
    }

    .phone-group {
        width: 110%; /* Make sure the phone group takes full width on smaller screens */
    }

    .country-code {
        width: 20%; /* Reduce the width of the country code field on smaller screens */
    }

    #phone {
        width: 100%; /* Adjust the phone input field width on smaller screens */
    }
}
