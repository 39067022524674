.download-buttons {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
  }
  
  .download-button {
    padding: 10px 20px;
    background-color: #1f1f1f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .download-button:hover {
    background-color: #000;
  }
  