.all-values-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 16px;
    padding: 10px;
    width: auto;
    margin: 0 auto;
    background-color: #1A1B1F;
    padding-top: 5rem;
    padding-bottom: 5rem;
    justify-content: center;
  }
  
  .value-card {
    flex: 0 0 auto;
    max-width: 200px;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    margin-right: 50px;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .value-icon {
    font-size: 4rem;
    color: #fff; /* Default color */
    text-align: center;
    margin-bottom: 10px;
    transition: color 0.3s ease; /* Smooth color transition on hover */
  }
  
  .value-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
    color: #fff; /* Default color */
    text-transform: uppercase;
    position: relative; /* Needed for the underline effect */
    transition: color 0.3s ease; /* Smooth color transition on hover */
  }
  
  .value-title::before {
    content: '';
    position: absolute;
    bottom: -8px; /* Distance from the text */
    left: 50%; /* Start at the center */
    width: 0; /* Initial width */
    height: 2px;
    background-color: var(--red); /* Underline color */
    transition: all 0.8s ease; /* Smooth width expansion */
  }
  
  .value-sentence {
    font-size: 14px;
    color: #9a9a9a;
    text-align: center;
    line-height: 1.8;
  }
  
  /* Hover effect */
  .value-card:hover .value-icon {
    color: var(--red); /* Change icon color on hover */
  }
  
  .value-card:hover .value-title {
    color: var(--red); /* Change title color on hover */
  }
  
  .value-card:hover .value-title::before {
    width: 100%; /* Expand underline to full width */
    left: 0; /* Start from the beginning of the text */
  }
  

  @media (max-width: 786px) {
    .all-values-container {
      flex-wrap: wrap; /* Allow wrapping for smaller screens */
      justify-content: center; /* Center the items */
      gap: 12px; /* Reduce gap between items */
      width: auto;
    }
  
    .value-card {
      max-width: 80%; /* Cards will take full width */
      padding: 15px;
      margin-right: 0;
      text-align: center; /* Center-align content for smaller screens */
    }
  
    .value-icon {
      font-size: 3rem; /* Reduce icon size */
    }
  
    .value-title {
      font-size: 18px; /* Reduce title size */
      margin-bottom: 1.5rem;
    }
  
    .value-sentence {
      font-size: 12px; /* Reduce sentence font size */
      line-height: 1.5; /* Adjust line-height for readability */
    }
  }