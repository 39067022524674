.blogDetailsContainer {
    width: 70%;
    margin: 2rem auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .blogHeader {
    text-align: center;
  }
  
  .blogTitle {
    font-size: 2rem;
    margin: 1rem 0;
    font-weight: bold;
    color: var(--grey);
    text-transform: uppercase;
  }
  
  .blogDate {
    font-size: 0.9rem;
    color: #666;
  }
  
  .blogContent {
    margin-top: 1.5rem;
    line-height: 1.5;
  }
  
  
  .blogBody {
    border-top: 1px solid #eaeaea;
    padding-top: 1.5rem;
    text-align: justify; /* Justify text in the blog body */
  }
  
  .blogBody p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    margin: 0; /* Remove default margin to control spacing */
  }
  
  @media (max-width: 786px) {
    .blogDetailsContainer {
      padding: 15px; /* Reduce padding for smaller screens */
      width: 90%;
    }
  
    .blogTitle {
      font-size: 1.5rem; /* Reduce title size */
    }
  
    .blogDate {
      font-size: 0.8rem; /* Adjust date size */
    }
  
    .blogDetails,
    .blogBody p {
      font-size: 0.9rem; /* Reduce font size for details and body */
    }
  
    .blogBody {
      padding-top: 1rem; /* Adjust padding for smaller screens */
    }
  }
  