/* General styles for links to remove underline and blue color */
a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from the parent element */
}

/* Map container styles */
.office-map-container {
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 4rem;
}

.office-map-section {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
    background-color: var(--white);
}

.office-left-section {
    flex: 1;
    padding: 50px;
}

.office-right-section {
    padding: 40px;
    flex: 1;
}

.office-head {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 700;
}

.office-city {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 300;
}

.office-location {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 300;
}

.office-contact-number {
    font-size: 15px;
    margin-bottom: 10px;
    font-weight: 300;
}

/* Adjust map container size */
.office-map-container .office-map-section .office-right-section {
    height: 500px; /* Adjust as needed */
}

/* Responsive styles for screens with a maximum width of 786px */
@media only screen and (max-width: 786px) {
    .office-map-section {
        flex-direction: column;
        width: auto;
    }

    .office-left-section, .office-right-section {
        padding: 20px;
        flex: none;
        width: auto;
    }

    .office-map-container .office-map-section .office-right-section {
        height: 400px; /* Adjust as needed */
    }

    .office-head {
        font-size: 20px;
        margin-bottom: 8px;
    }

    .office-city, .office-location, .office-contact-number {
        font-size: 14px;
        margin-bottom: 8px;
    }
}
