/* Main container with background image and parallax effect */
.home-contactus-container {
  width: 100%;
  height: 450px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Sticky background */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  transition: background-position 0.2s ease-in-out;
}

/* Dark overlay for better readability */
.home-contactus-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Slightly darker overlay */
  z-index: 1;
}

/* Content container for text and button */
.home-contactus-content {
  background-color: #1A1B1F;
  width: 300px;
  height: 300px;
  margin-left: 15rem;
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2; /* Ensures it's on top of the background and overlay */
}

/* Internal content for aligning text and button */
.home-contactus-contentInternal {
  margin-bottom: 3rem;
  text-align: left;
  margin-left: 3rem;
  z-index: 2;
}

/* Text styling */
.home-contactus-text {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
  margin-right: 5rem;
}

/* Responsive Design - For screens 786px and smaller */
@media (max-width: 786px) {
  .home-contactus-container {
    height: 400px; /* Adjust height for smaller screens */
    margin-top: 0;
    background-position: center;
  }

  .home-contactus-content {
    width: 85%;
    height: 330px;
    margin-left: 0;
    margin-top: 0;
    padding: 20px;
    margin: 0 auto;
  }

  .home-contactus-contentInternal {
    margin-bottom: 2rem;
    margin-left: 0;
    text-align: center;
  }

  .home-contactus-text {
    font-size: 2rem;
    margin-right: 0;
    font-weight: 600;
  }
}
