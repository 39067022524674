/* OfficeImages.css */

.OfficeImage-slider {
    text-align: center;
    margin: 20px auto;
    width: 90%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .OfficeImage-container {
    margin-bottom: 20px;
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
  }
  
  .OfficeImage-image {
    width: 100%;
    height: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .OfficeImage-image:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .image-wrapper:hover .image-overlay {
    opacity: 1;
  }
  
  .image-description {
    padding: 20px;
    text-align: left;
  }
  
  /* Slick carousel arrows */
  .slick-prev,
  .slick-next {
    background-color: transparent;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border: none;
    cursor: pointer;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    color: var(--third-color);
  }
  
  .slick-prev::before,
  .slick-next::before {
    font-size: 30px;
    color: #000;
  }
  
  /* Position adjustments for arrows */
  .slick-prev {
    left: -50px;
  }
  
  .slick-next {
    right: -50px;
  }
  
  @media (max-width: 786px) {
    .OfficeImage-slider {
      width: 80%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    .OfficeImage-container {
      margin-bottom: 15px;
    }
  
    .OfficeImage-image {
      height: 300px;
      padding: 10px;
    }
  
    .slick-prev,
    .slick-next {
      width: 25px;
      height: 25px;
      background-color: transparent;
    }
  
    .slick-prev::before,
    .slick-next::before {
      font-size: 25px;
    }
  
    .slick-prev {
      left: -30px;
    }
  
    .slick-next {
      right: -30px;
    }
  
    .image-description {
      padding: 10px;
    }
  }
  