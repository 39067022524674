.faqs-heading {
    font-size: 40px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .faqs-wrapper {
    max-width: 1200px; /* Adjust container width as needed */
    margin: 0 auto; /* Align the container in the center */
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
  
  .faq-block {
    margin-bottom: 22px;
    color: #000;
  }
  
  .faq-question {
    cursor: pointer;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    border-bottom: 1px solid #ddd;
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center items vertically */
    transition: background-color 0.3s ease;
    font-size: 12px;
    font-weight: 500;
    color: #000;
  }
  
  .faq-question-icon {
    margin-right: 10px; /* Adjust margin to create space between the icon and the question text */
    color: #d62c2c; /* Set the color of the question icon */
    font-size: 18px; /* Adjust the font size of the question icon */
  }
  
  .faq-questionTitle{
  color: #000;
  }
  
  .faq-question:hover {
    color: #d62c2c;
  }
  
  .faq-question.active {
    color: #d62c2c;
    border-bottom: 1px solid #d62c2c;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .faq-icon-toggle {
    font-size: 20px;
    font-weight: 300;
    margin-left: auto; /* Position the icon on the extreme right */
  }
  
  .faq-icon-toggle.active {
    color: #d62c2c; /* Change color to #d62c2c when active */
  }
  
  /* Media query for mobile */
  @media (max-width: 767px) {
    .faqs-heading {
      margin-top: 3rem;
      font-size: 30px;
    }
    
    .faq-question {
      font-size: 8px;
    }
    
    .faq-question-icon {
      font-size: 16px;
    }
  }
  
  /* Media query for screens up to 1080px */
  @media (max-width: 1080px) {
    .faqs-wrapper {
      padding: 0 20px; /* Add padding to the container */
    }
  }
  