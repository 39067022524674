/* General container styling */
.events-video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    max-width: 75%;
    margin: 0 auto;
  }
  
  .events-video-title {
    margin-top: 2rem;
    font-size: 1rem;
    color: #000;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    
  }
  
  .events-video-type-circle {
    width: 6px;
    height: 6px;
    background-color: #000;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .events-video-subtitle {
    width: 70%;
    text-align: center;
    color: #000;
    margin-bottom: 5rem;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
  }
  
  .events-video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden;
    background-color: #000;
    border-radius: 40px;
  }
  
  .events-video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
  }
  
  .no-events-video-message {
    font-size: 1.2rem;
    color: #999;
    text-align: center;
    padding: 20px;
  }
  
  @media (max-width: 786px) {
    .events-video-wrapper {
      padding: 15px;
      max-width: 90%;
    }
  
    .events-video-title {
      font-size: 0.8rem;
    }

    .events-video-container {
        border-radius: 20px;
    }
  
    .events-video-subtitle {
      font-size: 1.7rem;
      width: 80%;
      margin-bottom: 3rem;
    }
  
    .events-video-type-circle {
      width: 5px;
      height: 5px;
    }
  }
  
