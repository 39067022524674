/* Container for scrollable cards */
.amenities-scroll-container {
  text-align: center;
  padding: 2rem;
  margin: 0 auto;
  width: auto;
  background-color: var(--grey);
  margin-top: 5rem;
  padding-bottom: 4rem;
}

.amenities-title {
  font-size: 0.9rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  text-align: left;
  margin-left: 13rem;
}

.amenities-ProjectName{
  color: #fff;
  letter-spacing: 0.4rem;
  text-align: left;
  font-size: 30px;
  margin-top: -0.5rem;
  margin-left: 13rem;
}

.amenities-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Wrapper for the cards */
.amenities-card-wrapper {
  display: flex;
  width: 80%; /* Adjusted width to fit the number of visible cards */
  justify-content: space-around;
  overflow: hidden;
}

.amenity-card {
  width: 30%; /* Adjust width to show 3 cards at once on larger screens */
  height: 400px;
  perspective: 1000px;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.amenity-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.amenity-card:hover .amenity-card-inner {
  transform: rotateY(180deg);
}

/* Front and back of the card */
.amenity-card-front,
.amenity-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.amenity-card-back {
  transform: rotateY(180deg);
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column; /* Stack the quote and sentence vertically */
  justify-content: center;
  align-items: center;
  left: -0.7rem;
}

.amenity-card-front {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  z-index: 1;
}

/* Text Overlay on Front */
.amenity-text-overlay {
  padding: 10px;
  color: white;
}

.amenity-name {
  font-size: 2rem;
  color: white;
  font-weight: 300;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

/* Back face content */
.amenity-quote {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.amenity-sentence {
  font-size: 0.9rem;
  line-height: 1.4;
  text-align: center;
}

/* Navigation buttons */
.prev-button, .next-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}

.prev-button:hover, .next-button:hover {
  background-color: #555;
}

/* Dots navigation */
.dots-navigation {
  margin-top: 2rem;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

.amenity-card-front::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the darkness with opacity (0.5 = 50% dark) */
  z-index: -1; /* Ensure the overlay is behind the content */
  border-radius: inherit; /* Match the card's border-radius if it has one */
}

/* Media query for mobile screens */
@media screen and (max-width: 786px) {
  .amenity-card {
    width: 100%; /* Show only one card on mobile */
  }

  .amenities-card-wrapper {
    width: 100%; /* Ensure the card wrapper is full width */
    justify-content: center;
  }

  /* Show navigation buttons */
  .prev-button, .next-button {
    display: none;
  }

  .amenities-title {
    margin-left: 2rem;
    font-size: 1rem;
  }

  .amenities-ProjectName {
    margin-left: 2rem;
    font-size: 1.5rem;
  }
}
