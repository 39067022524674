/* Sticky floating button */
.floating-button {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #000000;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
}

.floating-button:hover{
    background-color: var(--grey);
}
  
/* Pop-up form */
.floating-form {
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: white;
    padding: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
  
.floating-form h3 {
    margin-bottom: 15px;
}
  
.floating-form button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
}
  
.floating-form button:hover {
    background-color: var(--grey);
}
  
/* Form input and select field styling */
.visit-form input, .visit-form select {
    display: block;
    width: calc(100% - 30px); /* Ensure uniform width for all inputs */
    padding: 15px; /* Uniform padding */
    margin-bottom: 15px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 16px;
    color: #333;
    outline: none;
    transition: border-color 0.3s ease;
}

/* Style for input/select field when focused */
.visit-form input:focus, .visit-form select:focus {
    border-color: #000000;
    background-color: #fff;
}

/* Style for options inside the select dropdown */
.visit-form option {
    color: #333;
    background-color: #fff;
    padding: 15px;
}

/* Submit button inside the form */
.visit-form button {
    width: 100%;
    padding: 15px;
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
}

.visit-form button:hover {
    background-color: var(--grey);
}


/* Media query for 786px and below */
@media (max-width: 786px) {
    .floating-form {
        bottom: 80px;
        right: 10px;
        padding: 20px; /* Reduce padding for smaller screens */
    }
  
    .floating-button {
        width: 50px; /* Smaller button size */
        height: 50px;
        bottom: 40px;
        font-size: 20px; /* Smaller icon */
    }
  
    .floating-form h3 {
        font-size: 16px; /* Smaller font size for headings */
    }
  
    .floating-form button {
        padding: 8px; /* Reduce padding for buttons */
        font-size: 14px; /* Smaller button text */
    }
  
    /* Form input and select field styling for smaller screens */
    .visit-form input, .visit-form select {
        width: calc(100% - 20px); /* Reduce width for inputs */
        padding: 10px; /* Reduce padding */
        font-size: 14px; /* Smaller font size */
    }
  
    .visit-form button {
        padding: 12px; /* Reduce padding for submit button */
        font-size: 14px; /* Smaller button text */
    }
  }