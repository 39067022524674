.loading-wave {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* Spacing between the bars */
    margin: 10rem;
  }
  
  .loading-bar {
    width: 4px; /* Width of each bar */
    height: 20px; /* Initial height of each bar */
    background-color: #0098da; /* Blue color for the bars */
    animation: wave 1s infinite ease-in-out;
  }
  
  .loading-bar:nth-child(1) {
    animation-delay: -0.4s;
  }
  .loading-bar:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loading-bar:nth-child(3) {
    animation-delay: -0.2s;
  }
  .loading-bar:nth-child(4) {
    animation-delay: -0.1s;
  }
  .loading-bar:nth-child(5) {
    animation-delay: 0s;
  }
  
  @keyframes wave {
    0%, 100% {
      height: 20px;
    }
    50% {
      height: 50px; /* Maximum height during the wave */
    }
  }
  