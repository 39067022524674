/* Base styles for the button */
.btn {
    background-color: transparent;
    color: #000;
    padding: 17px 30px;
    cursor: pointer;
    font-weight: bold;
    font-size: 17px;
    border: 2px solid #000;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    /* Optional: add margin to separate buttons */
  }
  
  /* Hover styles for the button */
  .btn:hover {
    background-color: #000;
    border: 2px solid #000;
    color: #fff;
    transform: scale(1.1);
  }
  
  /* Base styles for the icon */
  .icon-HomeIntro {
    margin-left: 10px;
    font-size: 1.2em;
    transition: transform 0.3s ease;
  }
  
  /* Hover styles for the icon */
  .btn:hover .icon-HomeIntro {
    transform: translateX(5px);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .btn {
      padding: 14px 25px; /* Adjust padding for smaller screens */
      font-size: 15px; /* Adjust font size for smaller screens */
    }
  
    .icon-HomeIntro {
      font-size: 1em; /* Adjust icon size for smaller screens */
      margin-left: 8px; /* Adjust margin for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .btn {
      font-size: 14px; /* Further adjust font size for very small screens */
      padding: 12px 20px; /* Further adjust padding for very small screens */
      margin: 0 auto; /* Center button for very small screens */
    }
  
    .icon-HomeIntro {
      font-size: 0.9em; /* Further adjust icon size for very small screens */
      margin-left: 6px; /* Further adjust margin for very small screens */
    }
  }
  