/* Default styles */
.testimonial-container {
    padding: 20px; /* Top and bottom padding */
    padding-left: 10rem; /* Left padding */
    padding-right: 10rem; /* Right padding */
    margin-bottom: 3rem; /* Reduce margin for responsiveness */
}


.testimonial-title {
    font-size: 1rem; /* Adjust title font size for responsiveness */
    color: #222; /* Title color */
    font-weight: 600; /* Title font weight */
    text-align: left; /* Center the title */
    margin-bottom: 1rem; /* Reduce space below the title for responsiveness */
    margin-top: 5rem; /* Adjust top margin for responsiveness */

}

.testimonial-para{
    font-size: 2rem; /* Adjust title font size for responsiveness */
    color: #666; /* Title color */
    font-weight: 600; /* Title font weight */
    text-align: left; /* Center the title */
    margin-bottom: 2rem; /* Reduce space below the title for responsiveness */
    margin-top: 1rem; /* Adjust top margin for responsiveness */
}

.testimonial-carousel-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-carousel-container {
    width: 100%; /* Adjust the width of the carousel container for responsiveness */
}

/* Styles for the dots */
.slick-dots {
    text-align: center; /* Center the dots */
    margin-top: 1rem; /* Adjust space above the dots for responsiveness */
    margin-bottom: 3rem;
}



/* Scoped dots styling for the slider within blog-latest-container */
.testimonial-container .slick-dots li button:before {
    font-size: 12px;
    color: rgb(0, 0, 0); /* Change dot color to white */
    opacity: 0.5; /* Adjust opacity to make it less bright by default */
  }
  
  .testimonial-container .slick-dots li.slick-active button:before {
    opacity: 1; /* Make the active dot fully visible */
    color: rgb(0, 0, 0); /* Keep active dot white */
  }
  

@media (max-width: 786px) {
    .testimonial-container {
        padding-left: 2rem; /* Reduce left padding */
        padding-right: 2rem; /* Reduce right padding */
        margin-bottom: 5rem; /* Adjust margin for smaller screens */
    }

    .testimonial-title {
        font-size: 2rem; /* Reduce font size for smaller screens */
        margin-bottom: 1rem; /* Adjust space below the title */
        margin-top: 1rem; /* Adjust top margin */
    }

    .testimonial-para {
        font-size: 1.5rem; /* Reduce font size for smaller screens */
        margin-bottom: 1rem; /* Adjust space below the text */
        margin-top: 1rem; /* Adjust top margin */
    }

    .slick-dots li button {
        font-size: 1rem; /* Adjust the dot size for smaller screens */
        width: 1.5rem; /* Adjust the dot width */
        height: 1.5rem; /* Adjust the dot height */
        line-height: 1.5rem; /* Center dot content vertically */
    }
}
