:root {
  --black: #000000;
  --red: #cc0200;
  --white: #ffffff;
  --transparent-white: rgba(255, 255, 255, 0.9);
}

/* Default NavBar styling */
.NavBarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background-color: transparent;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Stick to the top */
  z-index: 1000; /* Ensure it stays on top */
  transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.5s ease;
  box-shadow: none; /* No box-shadow by default */
}

/* Transparent state for navbar text and icons */
.NavBarContainer.transparent {
  background-color: transparent;
  box-shadow: none; /* No shadow when transparent */
}

.NavBarContainer.transparent .NavBarLink,
.NavBarContainer.transparent .IconLink {
  color: var(--white); /* Text and icons are white when transparent */
}

.NavBarContainer.transparent .NavBarLink::after {
  background-color: var(--white); /* White underline when transparent */
}

/* Scrolled state with white background and black text/icons */
.NavBarContainer.scrolled {
  background-color: var(--white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Apply box-shadow after scroll */
}

.NavBarContainer.scrolled .NavBarLink,
.NavBarContainer.scrolled .IconLink {
  color: var(--black); /* Text and icons are black when scrolled */
}

.NavBarContainer.scrolled .NavBarLink::after {
  background-color: var(--red); /* Red underline when scrolled */
}

/* White background for all other pages */
.NavBarContainer.white-background {
  background-color: var(--white);
}

.NavBarContainer.white-background .NavBarLink,
.NavBarContainer.white-background .IconLink {
  color: var(--black); /* Text and icons are black by default */
}

.NavBarContainer.white-background .NavBarLink::after {
  background-color: var(--red); /* Red underline by default */
}

/* Logo styling */
.LogoImage {
  max-height: 70px;
  margin-right: auto;
  padding: 10px;
}

/* Navbar content */
.NavContent {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.NavBarList {
  display: flex;
  align-items: center;
  list-style: none;
}

/* Icon styling */
.NavIcons {
  display: flex;
  align-items: center;
  margin-left: 20px;
  position: relative;
}

.NavIcons::before {
  content: '';
  width: 1px;
  height: calc(100% - 4px);
  background-color: currentColor; /* Inherits current text color */
  position: absolute;
  left: -10px;
  top: 2px;
}

.IconLink {
  margin-left: 20px;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.5s ease;
}

.IconLink:hover {
  color: var(--red); /* Hover color */
}

/* Navbar Links */
.NavBarLink {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  transition: color 0.3s ease;
  white-space: nowrap;
  position: relative;
}

.NavBarLink::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: -5px;
  transform: scaleX(0);
  transition: transform 0.3s ease, transform 0.8s ease;
}

.NavBarLink:hover::after {
  transform: scaleX(1);
}

.ChevronIcon {
  margin-left: 8px;
  transition: transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transform-origin: center;
}

.rotate-up {
  transform: rotate(180deg);
}

.rotate-down {
  transform: rotate(0deg);
}

.NavBarItem {
  margin: 0 35px;
  position: relative;
  cursor: pointer;
}

.NavBarLink,
.SubMenuLink {
  text-decoration: none;
  text-transform: uppercase;
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  position: relative;
  display: inline-block;
  white-space: nowrap; /* Prevent line break */
}

.NavBarLink:hover,
.SubMenuLink:hover,
.NavBarLink.active,
.SubMenuLink.active {
  color: var(--red); /* Active and hover color */
}

.NavBarLink::after,
.SubMenuLink::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; /* Adjust height if needed */
  background-color: var(--red); /* Underline color */
  left: 0;
  bottom: -5px; /* Adjust bottom position as needed */
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease, transform 0.8s ease;
}

.NavBarLink:hover::after,
.SubMenuLink:hover::after,
.NavBarLink.active::after,
.SubMenuLink.active::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* SubMenu styling */
.SubMenuList {
  opacity: 0;
  max-height: 0;
  overflow: hidden; /* Hide content that overflows */
  position: absolute;
  top: 4rem;
  left: 0;
  background-color: var(--white);
  padding: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: opacity 0.8s ease, max-height 0.8s ease;
  min-width: 150px; /* Minimum width for the submenu */
}

.SubMenuList.show {
  opacity: 1;
  max-height: 300px; /* Adjust to fit your content */
  padding: 10px 0; /* Add padding when visible */
}

.SubMenuItem {
  padding: 15px 50px;
  position: relative; /* Required for the underline effect */
}
