.Services-Container {
    background-color: var(--grey); /* Grey background color for the container */
    width: 100%;
    padding: 2rem 0; /* Padding at the top and bottom to give it space */
}

.services-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
    gap: 2rem;
    width: 80%;
    margin: 0 auto; /* Center the grid */
}

.service-card {
    padding: 1rem;
    border-radius: 10px;
    text-align: left;
    color: var(--white);
}

.service-photo {
    width: 100%;
    height: 200px;
    object-fit: cover;
    filter: grayscale(100%); /* Make the image black and white */
    transition: filter 0.3s ease-in-out; /* Smooth transition */
}

.service-photo:hover {
    filter: grayscale(0%); /* Restore the image to color on hover */
}


.service-title {
    font-size: 1.2rem;
    margin: 1rem 0;
    text-transform: uppercase;
}

.service-description {
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

.service-sentence {
    font-size: 1rem;
    margin-top: 1rem;
    line-height: 1.8;
}

.read-more-btn {
    color: white;
    width: 100%;
    background-color: var(--grey);
    border: 1px solid #000;
    padding: 1rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Main container for smaller screens */
@media (max-width: 786px) {
    .Services-Container {
        background-color: var(--grey); /* Keep the grey background */
        width: 100%;
        padding: 1rem 0; /* Adjust padding for smaller screens */
    }

    .services-list {
        display: grid;
        grid-template-columns: 1fr; /* Change to 1 card per row */
        gap: 1.5rem;
        width: 90%; /* Make the grid width a bit larger to fit the screen better */
        margin: 0 auto; /* Center the grid */
    }

    .service-card {
        padding: 0.8rem; /* Reduce padding for smaller screens */
        border-radius: 8px;
        text-align: left;
        color: var(--white);
    }

    .service-photo {
        width: 100%;
        height: 180px; /* Adjust height for smaller screens */
        object-fit: cover;
        filter: grayscale(100%);
        transition: filter 0.3s ease-in-out;
    }

    .service-photo:hover {
        filter: grayscale(0%);
    }

    .service-title {
        font-size: 1rem; /* Reduce font size */
        margin: 0.8rem 0;
        text-transform: uppercase;
    }

    .service-description {
        font-size: 0.75rem; /* Smaller font for description */
        margin-bottom: 0.8rem;
    }

    .service-sentence {
        font-size: 0.9rem; /* Adjust font size */
        margin-top: 0.8rem;
        line-height: 1.6; /* Reduce line-height */
    }

    .read-more-btn {
        color: white;
        width: 100%;
        background-color: var(--grey);
        border: 1px solid #000;
        padding: 0.8rem; /* Reduce button padding */
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}
