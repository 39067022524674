/* General container for the map */
.map-container {
  width: 100%;
  height: 70vh;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

/* Styling for the project name */
.ProjectMapName {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin-bottom: 10px;
}

/* Styling for the project address */
.ProjectMapAddress {
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 5px;
}

.ProjectMapAddressFull {
  font-size: 0.9rem;
  color: #777;
  text-align: center;
  margin-bottom: 10px;
}


/* Styling for project link inside popup */
.project-link {
  display: block;
  color: red;
  text-decoration: none;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 2rem;
}

.project-link:hover {
  text-decoration: underline;
}

/* Tooltip styling */
.project-name-tooltip {
  font-size: 14px;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 4px;
  text-align: center;
}

/* Custom marker icon */
.custom-svg-marker {
  cursor: pointer;
}



/* Responsive styles for mobile */
@media screen and (max-width: 768px) {
  .map-container {
    height: 40vh; /* Decrease the map height on smaller screens */
  }

  .ProjectMapName {
    font-size: 1.2rem;
  }

  .ProjectMapAddress,
  .ProjectMapAddressFull {
    font-size: 0.9rem;
  }


  .project-name-tooltip {
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .map-container {
    height: 35vh; /* Further reduce the map height for very small screens */
    margin-bottom: 1rem;
  }

  .ProjectMapName {
    font-size: 1rem;
  }

  .ProjectMapAddress,
  .ProjectMapAddressFull {
    font-size: 0.8rem;
  }



  .project-name-tooltip {
    font-size: 10px;
  }
}


