.HomeAboutAvenueContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 600px;
    background-color: #1A1B1F;
  }
  
  .HomeAboutAvenueVideoSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center; /* Ensure the text is centered */
  }
  
  .HomeAboutAvenueVideo {
    width: 100%;
    height: 400px;
    max-width: 700px;
    border: none;
  }
  
  .HomeAboutAvenueVideoText {
    margin-top: 15px;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--white);
    text-align: center; /* Center the text */
  }
  
  
  .HomeAboutAvenueTextSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 40px;
  }
  
  .HomeAboutAvenueTitle {
    font-size: 1rem;
    font-weight: 700;
    color: var(--white);
  }
  
  .HomeAboutAvenueSentence {
    font-size: 2.7rem;
    line-height: 1.5;
    margin: 20px 0;
    margin-right: 10rem;
    color: var(--white);
  }
  
  .HomeAboutAvenueWord {
    color: var(--red);
    font-weight: 700;
  }
  
  .HomeAboutAvenueLink {
    margin-top: 20px;
    font-size: 1rem;
    color: var(--red);
    text-decoration: none;
    border-bottom: 2px solid var(--red);
    transition: color 0.3s ease, border-bottom 0.3s ease;
  }
  
  .HomeAboutAvenueLink:hover {
    color: var(--black);
    border-bottom: 2px solid var(--black);
  }
  
  /* Optional typing effect for the word */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  .typing {
    border-right: 2px solid var(--red);
    animation: typing 0.75s steps(30, end), blink-caret 0.75s step-end infinite;
  }
  
  /* Blinking caret */
  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: var(--red);
    }
  }
  


  @media (max-width: 786px) {
    .HomeAboutAvenueContainer {
      flex-direction: column; /* Stack sections vertically */
      justify-content: center; /* Center content vertically */
      align-items: center;
      height: auto; /* Adjust height to fit content */
      padding: 20px; /* Add padding for better spacing */
      width: auto;
    }
  
    .HomeAboutAvenueVideoSection {
      flex: none; /* Ensure video section adjusts its size */
      width: 100%; /* Full width */
      max-width: 100%; /* Allow it to take up full width */
      margin-bottom: 20px; /* Add space below the video section */
    }
  
    .HomeAboutAvenueVideo {
      width: 100%; /* Full width */
      height: 230px; /* Maintain aspect ratio */
      max-width: 100%; /* Ensure it takes full width */
      margin-top: 3rem;
    }
  
  
    .HomeAboutAvenueTextSection {
      flex: none; /* Ensure text section adjusts its size */
      align-items: left; /* Center align text for smaller screens */
      text-align: left; /* Center align text */
      margin-left: 0; /* Remove the left margin */
      margin-bottom: 20px; /* Add margin below the text section */
      margin-bottom: 5rem;
      margin-top: 3rem;
    }
  
    .HomeAboutAvenueTitle {
      font-size: 1rem; /* Increase title font size for better readability */
    }
  
    .HomeAboutAvenueSentence {
      font-size: 2.8rem; /* Adjust the sentence font size */
      line-height: 1.4;
      margin: 20px 0;
      margin-right: 0; /* Remove the right margin */
      color: var(--white);
    }
  
    .HomeAboutAvenueLink {
      font-size: 1rem; /* Keep link font size */
      margin-top: 15px;
    }
  
    /* Ensure typing effect is responsive */
    .typing {
      font-size: 2.8rem; /* Adjust typing text size */
      width: 100%;
    }
  }
  