/* Main Container */
.ProjectImage-Container {
    padding: 2rem;
    text-align: center;
    width: auto;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
  }
  
  /* Title */
  .ProjectImage-title1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .ProjectImage-title2 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  /* Slider Container */
  .ProjectImage-carousel {
    width: auto;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Slide Wrapper */
  .carousel-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
  }
  
  /* Image Style */
  .carousel-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
    max-height: 80vh;
    transition: transform 0.3s ease;
  }
  
  /* Hover Zoom (optional) */
  .carousel-image:hover {
    transform: scale(1.02);
  }
  
  /* Dots Style */
  .slick-dots li button:before {
    color: #888;
    font-size: 10px;
  }
  
  .slick-dots li.slick-active button:before {
    color: #000;
  }
  

  .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.6rem;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-size: 1.5rem;
    transition: background 0.3s ease;
  }
  
  .custom-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .custom-arrow.prev {
    left: 10px;
  }
  
  .custom-arrow.next {
    right: 10px;
  }
  
  
  /* ✅ Tablet Screens */
  @media (max-width: 1024px) {
    .ProjectImage-title1 {
      font-size: 1.7rem;
    }
  
    .ProjectImage-title2 {
      font-size: 1.05rem;
    }
  
    .carousel-image {
      max-height: 70vh;
    }
  }
  
  /* ✅ Mobile Screens */
  @media (max-width: 768px) {
    .ProjectImage-Container {
      padding: 0.8rem;
    }
  
    .ProjectImage-title1 {
      font-size: 1.5rem;
    }
  
    .ProjectImage-title2 {
      font-size: 0.95rem;
      margin-bottom: 1rem;
    }
  
    .carousel-slide {
      padding: 0.2rem;
    }
  
    .carousel-image {
      max-height: 80vh;
      border-radius: 3px;
    }
  

    .slick-dots li button:before {
        display: none;
      }

      .custom-arrow{
        display:none
      }
  }
  
  /* ✅ Extra Small Devices */
  @media (max-width: 480px) {
    .ProjectImage-title1 {
      font-size: 1.3rem;
    }
  
    .ProjectImage-title2 {
      font-size: 0.85rem;
    }
  
    .carousel-image {
      max-height: 50vh;
    }
  }
  