.project-intro-video {
    position: relative;
    width: auto;
    height: 105vh;
    margin-top: -6rem;
    background-color: #000;
    background-size: contain;
    background-attachment: fixed; /* Enable parallax effect */
    background-position: center;
    background-repeat: no-repeat;

  }
  
  /* Dark overlay */
  .project-intro-video::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Adjust the opacity to control darkness */
    z-index: 1; /* Ensure it stays behind the content */
  }
  
  .image-slider {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .quote-container {
    position: absolute;
    left: 5rem;
    bottom: 3rem;
    color: white;
    font-size: 1.5rem;
    max-width: 40%;
    z-index: 5;
    text-align: left;
  }
  
  .image-quote {
    font-weight: 300;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 1.8;
    letter-spacing: 0.9rem;
  }
  
  .number-navigation {
    position: absolute;
    top: 50%;
    right: 5rem;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    z-index: 5;
  }
  
  .number {
    padding: 10px;
    cursor: pointer;
    font-size: 25px;
    color: white;
    opacity: 0.3;
    transition: opacity 0.3s;
  }
  
  .number:hover,
  .number.active {
    opacity: 1;
    font-weight: bold;
    color: var(--red);
  }
  
  .navigation-controls {
    position: absolute;
    bottom: 5rem;
    right: 4rem;
    display: flex;
    gap: 15px;
    z-index: 5;
  }
  
  .nav-icon {
    font-size: 20px;
    color: white;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s;
  }
  
  .nav-icon:hover {
    opacity: 1;
    color: var(--red);
  }
  
  .left {
    margin-right: 20px;
  }
  
  @media (max-width: 786px) {
    .project-intro-video {
      margin-top: 4.8rem;
      height: 70vh; /* Ensure full height on mobile */
      background-size: cover;
      
    }
  
    .number-navigation {
      right: 2rem;
    }
  
    .number {
      font-size: 17px; /* Smaller number font size for better fit */
      padding: 5px;
    }
  
    .navigation-controls {
      bottom: 3rem;
      right: 2rem;
    }
  
    .nav-icon {
      font-size: 16px; /* Smaller icon size for better fit */
    }
  
    .quote-container {
      left: 1rem; /* Adjust position for smaller screens */
      bottom: 4rem;
      font-size: 1rem;
      max-width: 90%; /* Use more space on smaller screens */
    }
  
    .image-quote {
      font-size: 1rem;
      line-height: 1.4; /* Adjust line height for better fit */
    }
  }
  